import React from 'react';
import { Navigate } from 'react-router-dom';
import { isWithinMst } from 'app/helpers/routing';

const SafeRoute = ({
  accessToken,
  isMstTokenWasFetched,
  children,
}: {
  accessToken: string | null;
  isMstTokenWasFetched: boolean;
  children: any;
}) => {
  if (isWithinMst && !isMstTokenWasFetched) {
    return <Navigate to="/teams-login" />;
  }
  console.log('__accessToken__', accessToken);
  if (!accessToken) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default SafeRoute;
