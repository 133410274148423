import React from 'react';
import { Avatar } from '@fluentui/react-components';

import { EMOJIS_BY_ICONS_NAMES } from '../constants';
import { IStoryReaction } from '../../StoryFeedCard/interface';

import './ReactedUsersList.css';

const bc = 'users-reactions-list';

const REACTIONS_TO_SHOW_LIMIT = 50;

export default ({ reactions }: { reactions: IStoryReaction[] }) => {
  // restrict quantity of the users to be displayed once the user hovering over the icons block
  const reactionsToShow =
    reactions.length > REACTIONS_TO_SHOW_LIMIT ? reactions.slice(0, REACTIONS_TO_SHOW_LIMIT) : reactions;
  /*  
  1. Group users by reaction which they choose.
  create object with next signature:
  {
    heart: [user],
    fire: [user]},
  }
*/
  const UsersGroupedByEmojiType: { [key: string]: any[] } = {};
  reactionsToShow.forEach((reaction: IStoryReaction) => {
    const { emoji, user } = reaction;
    if (emoji in UsersGroupedByEmojiType) {
      UsersGroupedByEmojiType[emoji].push(user);
    } else {
      UsersGroupedByEmojiType[emoji] = [user];
    }
  });

  let moreReactionsCount = 0;
  if (reactions.length > REACTIONS_TO_SHOW_LIMIT) {
    moreReactionsCount = reactions.length - REACTIONS_TO_SHOW_LIMIT;
  }

  return (
    <div className={bc}>
      {Object.keys(UsersGroupedByEmojiType).map((iconName: string) => {
        // @ts-ignore
        const Icon = EMOJIS_BY_ICONS_NAMES[iconName];
        const users = UsersGroupedByEmojiType[iconName];
        return (
          <div key={iconName}>
            <div>
              <p className={`${bc}__icon-wrapper`}>
                Reacted with <Icon className={`${bc}__icon`} />
              </p>
            </div>
            {users.map((user) => {
              const { firstName, lastName, avatar, id, email } = user;
              const name = firstName && lastName ? `${firstName} ${lastName}` : email;
              return (
                <div className={`${bc}__user-card`} key={id}>
                  <Avatar
                    className={`${bc}__user-avatar`}
                    name={name}
                    image={{ src: avatar }}
                    badge={{ status: 'available' }}
                    size={40}
                  />
                  <p>{name}</p>
                </div>
              );
            })}
          </div>
        );
      })}
      {Boolean(moreReactionsCount) && (
        <div className={`${bc}__more-users-count`}> And {moreReactionsCount} user(s) more...</div>
      )}
    </div>
  );
};
