import { connect } from 'react-redux';
import Launch from './Launch';
import WithNavigate from '../../hoc/WithNavigate';
import { fetchAccessToken } from '../../store/entities/identity/actions';
import { getAccessToken } from '../../store/entities/identity/selectors';
import { RootState } from '../../store';
import { getAccessTokenWasFetched } from '../../store/entities/queriesStatuses/selectors';

const mapStateToProps = (state: RootState) => ({
  accessToken: getAccessToken(state),
  accessTokenWasFetched: getAccessTokenWasFetched(state),
});

const mapDispatchToProps = {
  fetchAccessToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithNavigate(Launch));
