import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  addComment,
  fetchActivitiesStories,
  fetchUsersForMentions,
  fetchStoryForMention,
  loadMoreActivitiesStories,
  resetStories,
  setCommentReaction,
  setStoryReaction,
  updateCommentReaction,
  updateStoryReaction,
} from './actions';
import { IActivitiesStoriesState, IUserMentions } from './interfaces';
import { IUpdateStoryCommentReaction, IUpdateStoryReaction } from '../../../containers/Reactions/interface';
import {
  IComment,
  ICommentAndUser,
  IStoryCommentReaction,
  IStoryReaction,
} from '../../../containers/StoryFeedCard/interface';
import { IActivityCompletedStory, IPagination } from '../../../containers/StoryFeed/interface';

const initialState: IActivitiesStoriesState = {
  pending: true,
  loadMorePending: false,
  stories: [],
  storyForMentionModal: {
    story: {
      activityUserStory: {
        id: 0,
        activity: { id: 0, description: '', name: '', question: '' },
        activityId: 0,
        mentions: [],
        story: '',
        userId: 0,
      },
      activitiesUsersStoriesComments: [],
      timestamp: '',
      reactions: [],
      user: { email: '', avatar: '', firstName: '', lastName: '', id: 0 },
    },
  },
  usersForMentions: [],
  pagination: {
    limit: 0,
    page: 0,
    count: 0,
  },
};

const mutateState = ({
  state,
  action,
}: {
  state: typeof initialState;
  action: PayloadAction<{ data: IActivitiesStoriesState }> & { meta: { arg: IPagination } };
}) => {
  const {
    payload: { data },
    meta,
  } = action;
  state.pending = false;
  state.loadMorePending = false;
  state.pagination = data.pagination;
};
const handleFetchActivitiesStories = (state: typeof initialState, action: any) => {
  mutateState({ state, action });
  // on the first load, or when user pressing the refresh button - initial stories value must be rewrited
  state.stories = action.payload.data.updateStories;
  return state;
};

const handleResetStories = (state: typeof initialState, action: any) => {
  // on the first load, or when user pressing the refresh button - initial stories value must be rewrited
  state.stories = [];
  state.pending = true;
  return state;
};

const handleLoadMoreActivitiesStories = (state: typeof initialState, action: any) => {
  mutateState({ state, action });
  // on the first load, or when user pressing the refresh button - initial stories value must be rewrited
  state.stories = state.stories.concat(action.payload.data.updateStories);
  return state;
};

const handleUpdateCommentReaction = (
  state: typeof initialState,
  action: PayloadAction<{ data: IUpdateStoryCommentReaction }>,
) => {
  const {
    payload: { data },
  } = action;
  const { commentId, emoji, storyId, id } = data;
  const mentionModalStoryComments = state.storyForMentionModal.story.activitiesUsersStoriesComments;

  /* update comment reaction for mention modal */
  if (state.storyForMentionModal.story.activityUserStory.id === storyId) {
    const commentIndex = mentionModalStoryComments.findIndex((comment: any) => comment.id === commentId);
    const reactionIndex = mentionModalStoryComments[commentIndex].reactions.findIndex(
      (reaction: IStoryReaction) => reaction.id === id,
    );
    mentionModalStoryComments[commentIndex].reactions[reactionIndex].emoji = emoji;
  }
  /* update comment reaction in feed in case when story is loaded and displayed for feed */
  const storyIndex = state.stories.findIndex(
    (story: IActivityCompletedStory) => story.activityUserStory.id === storyId,
  );

  const commentIndex = state.stories[storyIndex]?.activitiesUsersStoriesComments.findIndex(
    (comment: IComment) => comment.id === commentId,
  );

  const reactionToUpdateIndex = state.stories[storyIndex]?.activitiesUsersStoriesComments[
    commentIndex
  ].reactions.findIndex((reaction: IStoryReaction) => reaction.id === id);

  if (state.stories[storyIndex]) {
    state.stories[storyIndex].activitiesUsersStoriesComments[commentIndex].reactions[reactionToUpdateIndex].emoji =
      emoji;
  }

  return state;
};

const handleUpdateStoryReaction = (
  state: typeof initialState,
  action: PayloadAction<{ data: IUpdateStoryReaction }>,
) => {
  const {
    payload: {
      data: { id, storyId, emoji },
    },
  } = action;
  /* update story for mention modal */
  if (state.storyForMentionModal.story.activityUserStory.id === storyId) {
    const reactionIdInMentionModal = state.storyForMentionModal.story.reactions.findIndex(
      (reaction: IStoryReaction) => reaction.id === id,
    );
    state.storyForMentionModal.story.reactions[reactionIdInMentionModal].emoji = emoji;
  }
  const storyToUpdateIndex = state.stories.findIndex(
    (story: IActivityCompletedStory) => story.activityUserStory.id === storyId,
  );
  const reactionToUpdateIndex = state.stories[storyToUpdateIndex]?.reactions.findIndex(
    (reaction: IStoryReaction) => reaction.id === id,
  );
  /* if story available in feed - update it */
  if (state.stories[storyToUpdateIndex]) {
    state.stories[storyToUpdateIndex].reactions[reactionToUpdateIndex].emoji = emoji;
  }
  return state;
};

const handleSetCommentReaction = (
  state: typeof initialState,
  action: PayloadAction<{ data: IStoryCommentReaction }>,
) => {
  const {
    payload: { data },
  } = action;
  /* set comment reaction for mention modal */
  const { storyId, commentId } = data;
  if (storyId === state.storyForMentionModal.story.activityUserStory.id) {
    const commentIndexForMentionModal = state.storyForMentionModal.story.activitiesUsersStoriesComments.findIndex(
      (comment: any) => comment.id === commentId,
    );
    state.storyForMentionModal.story.activitiesUsersStoriesComments[commentIndexForMentionModal].reactions.push(data);
  }

  /* set comment reaction in feed in case when story is loaded and displayed for feed */
  const storyIndex = state.stories.findIndex(
    (story: IActivityCompletedStory) => story.activityUserStory.id === storyId,
  );
  const commentIndex = state.stories[storyIndex]?.activitiesUsersStoriesComments.findIndex(
    (comment: IComment) => comment.id === commentId,
  );
  state.stories[storyIndex]?.activitiesUsersStoriesComments[commentIndex].reactions.push(data);
  return state;
};

const handleSetStoryReaction = (state: typeof initialState, action: PayloadAction<{ data: IStoryReaction }>) => {
  const {
    payload: { data },
  } = action;
  const { storyId } = data;
  if (state.storyForMentionModal.story.activityUserStory.id === storyId) {
    state.storyForMentionModal.story.reactions.push(data);
  }
  const storyToUpdateIndex = state.stories.findIndex(
    (story: IActivityCompletedStory) => story.activityUserStory.id === storyId,
  );
  state.stories[storyToUpdateIndex]?.reactions.push(data);
  return state;
};

const handleAddComment = (state: typeof initialState, action: PayloadAction<{ data: ICommentAndUser }>) => {
  const {
    payload: {
      data: { comment, user },
    },
  } = action;
  const { storyId } = comment;
  const storyToUpdateIndex = state.stories.findIndex((story: any) => story.activityUserStory.id === storyId);
  /* set comment reaction for mention modal */
  if (state.storyForMentionModal.story.activityUserStory.id === storyId) {
    state.storyForMentionModal.story.activitiesUsersStoriesComments.unshift({ ...comment, user, reactions: [] });
  }
  /* update story in feed, in case when story is loaded and displayed for feed */
  state.stories[storyToUpdateIndex]?.activitiesUsersStoriesComments.unshift({ ...comment, user, reactions: [] });
  return state;
};

const handleFetchUsersForMention = (state: typeof initialState, action: PayloadAction<{ data: IUserMentions[] }>) => {
  const {
    payload: { data },
  } = action;
  state.usersForMentions = data;
  return state;
};
const handleFetchStoryForMention = (
  state: typeof initialState,
  { payload: { data } }: { payload: { data: IActivityCompletedStory } },
) => {
  // in case if story was recieved sucessfully - update state
  console.log({ data });
  if (data !== null) {
    state.storyForMentionModal.story = data;
  } else {
    // if story received as null - that means this story was removed, and state must be reset to initial
    state.storyForMentionModal.story = initialState.storyForMentionModal.story;
  }
  return state;
};
const activitiesStories = createSlice({
  name: 'activitiesStories',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchActivitiesStories.fulfilled.toString()]: handleFetchActivitiesStories,
    [loadMoreActivitiesStories.fulfilled.toString()]: handleLoadMoreActivitiesStories,
    [resetStories.toString()]: handleResetStories,
    [updateCommentReaction.fulfilled.toString()]: handleUpdateCommentReaction,
    [updateStoryReaction.fulfilled.toString()]: handleUpdateStoryReaction,
    [setCommentReaction.fulfilled.toString()]: handleSetCommentReaction,
    [setStoryReaction.fulfilled.toString()]: handleSetStoryReaction,
    [addComment.fulfilled.toString()]: handleAddComment,
    [fetchUsersForMentions.fulfilled.toString()]: handleFetchUsersForMention,
    [fetchStoryForMention.fulfilled.toString()]: handleFetchStoryForMention,
  },
});

export default activitiesStories.reducer;
