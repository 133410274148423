import React from 'react';
import { Form } from 'react-final-form';

import { ActivityCardSimple } from './ActivityCardSimple';

import { IActivityCardSimpleFormProps } from './interface';
import { cardAnswerParser } from 'app/helpers/cardAnswerParser';

const bc = 'activity-card-simple';
export const ActivityCardSimpleForm = ({ postActivity, activity, setLikeOrDislike }: IActivityCardSimpleFormProps) => {
  const { structure, userActivityId, likeDislike, activityId, likesCount } = activity;
  const [activityBody] = structure.body;
  return (
    <Form
      onSubmit={(formValues) => {
        const answer = cardAnswerParser({ activity: structure, formValues });
        postActivity({ answer, userActivityId });
      }}
      render={(props) => {
        const { handleSubmit } = props;
        return (
          <div className={bc}>
            <ActivityCardSimple
              userActivityId={userActivityId}
              activityId={activityId}
              type={activityBody.type}
              options={activityBody.options}
              title={activityBody.title}
              description={activityBody.description}
              image={activityBody.image}
              handleSubmit={handleSubmit}
              fieldName="simple-card"
              setLikeOrDislike={setLikeOrDislike}
              isLiked={likeDislike}
              likesCount={likesCount}
            />
          </div>
        );
      }}
    />
  );
};
