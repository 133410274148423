import { formatByISO } from '@prohabits/metaverse-v2';

export const settingsPage = (values: any) => {
  const errors = {
    phone: undefined,
  };

  if (values.notificationType.sms) {
    // select only positive integers with + in the beginning
    const phone = values.phone && values.phone.replace(/(?!^\+)\D/g, '');
    const formatISO = formatByISO as any;
    // @ts-ignore
    if (
      !phone ||
      phone.length !== formatISO[values.phoneISO].length ||
      phone.slice(0, 2) !== formatISO[values.phoneISO].code
    ) {
      // @ts-ignore
      errors.phone = 'invalid phone number for USA';
    }
  }
  return errors;
};
