import React from 'react';
import { Text, Image, Button } from '@fluentui/react-components';
import { Settings24Regular } from '@fluentui/react-icons';
import { NavLink } from 'react-router-dom';
import { IMAGE_PAUSED_PROHABITS } from '../constants/proHabitsPausedCard';
import { SignOut24Regular } from '@fluentui/react-icons/lib/fonts';
import { isWithinMst } from '../../helpers/routing';

import '../ProHabitsPausedCard/ProHabitsPausedCard.scss';

const bc = 'proHabits-paused-card';
const ProHabitsPausedCard = ({ logoutSuccess }: { logoutSuccess: () => void }) => {
  return (
    <div className={bc}>
      <div className={`${bc}__img-block`}>
        <Image className={`${bc}__img`} src={IMAGE_PAUSED_PROHABITS} alt="Paused ProHabits" />
        <div className={`${bc}__text-wrapper`}>
          <Text className={`${bc}__title`} size={400} weight="semibold">
            You have no current activities.
          </Text>
        </div>
      </div>
      <div className={`${bc}__button-block`}>
        <NavLink className={`${bc}__settings`} to="/settings">
          <Button icon={<Settings24Regular />}>
            <Text size={300}>Settings</Text>
          </Button>
        </NavLink>
        {!isWithinMst && (
          <NavLink className={`{bc}__settings`} to="/login">
            <Button icon={<SignOut24Regular />} onClick={() => logoutSuccess()}>
              <Text size={300}>Logout</Text>
            </Button>
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default ProHabitsPausedCard;
