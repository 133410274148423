import { ETheme } from '../StoryFeedCard/interface';
import { connect } from 'react-redux';

import TextAreaWithMention from './TextAreaWithMention';
import { fetchUsersForMentions } from '../../store/entities/activitiesStories/actions';
import { getUsersForMentions } from '../../store/entities/activitiesStories/selectors';

export const uiTheme = ETheme.DEFAULT;
const mapStateToProps = (state: any) => ({
  usersForMentions: getUsersForMentions(state),
});

const mapDispatchToProps = {
  fetchUsersForMentions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TextAreaWithMention as any) as any;
