import React from 'react';
import { ECardBodyType } from '@prohabits/metaverse-v2';

import ActivityCardTemplate from 'app/components/ActivityCardTemplate';
import Checkbox from 'app/components/Checkbox';
import MoodCheckIconsList from 'app/components/MoodCheckIconsList/MoodCheckIconsList';
import RadioButton from 'app/components/RadioButton';
import { IActivityCardSimpleProps } from './interface';
import SubmitAndLikeDislikeButtonsGroup from 'app/components/SubmitAndLikeDislikeButtonsGroup';

const bc = 'activity-card-simple';

export const ActivityCardSimple = ({
  type,
  options,
  title,
  description,
  image,
  fieldName,
  isWithinWizzard,
  userActivityId,
  activityId,
  isLiked,
  likesCount,
  handleSubmit,
  setLikeOrDislike,
}: IActivityCardSimpleProps) => {
  let body: any = null;
  if (type === ECardBodyType.checkbox) {
    body = <Checkbox checkboxes={options} fieldName={fieldName} />;
  }
  if (type === ECardBodyType.radio) {
    body = <RadioButton options={options} fieldName={fieldName} />;
  }
  if (type === ECardBodyType.moodcheck) {
    body = <MoodCheckIconsList fieldName={fieldName} />;
  }
  return (
    <div data-testid="simple-card" className={bc}>
      <ActivityCardTemplate header={'What is your opinion'} title={title} description={description} image={image}>
        {body}
        {!isWithinWizzard && (
          <SubmitAndLikeDislikeButtonsGroup
            /* this handleSubmit is required only in case when card is displayed not within a wizzard card */
            handleSubmit={handleSubmit as (e: any) => void}
            setLikeOrDislike={setLikeOrDislike}
            isLiked={isLiked}
            likesCount={likesCount}
            userActivityId={userActivityId}
            activityId={activityId}
          />
        )}
      </ActivityCardTemplate>
    </div>
  );
};
