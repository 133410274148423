import { TActivitiesCards } from '@prohabits/metaverse-v2';

export const cardAnswerParser = ({
  activity,
  formValues,
}: {
  activity: TActivitiesCards;
  formValues: any;
}): TActivitiesCards => {
  const { body } = activity;
  const parsedCardWithAnswers = body.map((card, idx) => {
    const updatedCardAnswer = [];
    /*
     this parser can be used either for wizzard card where field names depending
     on their order index or for simple cards which is not rendered within the loop
     */
    const answer = formValues[`step-${idx}`] || formValues['simple-card'];
    if (typeof answer === 'object') {
      return { ...card, answer: Object.keys(answer).filter((key) => answer[key]) };
    }
    // @ts-ignore
    updatedCardAnswer.push(answer);
    return { ...card, answer: updatedCardAnswer };
  });
  return { ...activity, body: parsedCardWithAnswers };
};
