import React from 'react';
import { ICommonCard } from './interface';

export const Angry = ({ svgClassName, wrapperClassName, width = 63, height = 63 }: ICommonCard) => {
  return (
    <div data-testid="angry-icon" className={wrapperClassName}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        className={svgClassName}
      >
        <path
          d="M30.9597 62.5707C47.97 62.5707 61.7597 48.7811 61.7597 31.7707C61.7597 14.7603 47.97 0.970703 30.9597 0.970703C13.9493 0.970703 0.159668 14.7603 0.159668 31.7707C0.159668 48.7811 13.9493 62.5707 30.9597 62.5707Z"
          fill="#FF8D4E"
        />
        <path
          d="M19.5907 29.862C20.8439 29.862 21.8599 28.1488 21.8599 26.0355C21.8599 23.9222 20.8439 22.209 19.5907 22.209C18.3375 22.209 17.3215 23.9222 17.3215 26.0355C17.3215 28.1488 18.3375 29.862 19.5907 29.862Z"
          fill="#303030"
        />
        <path
          d="M44.5588 26.0355C44.5588 28.1493 43.5435 29.862 42.2897 29.862C41.0358 29.862 40.0205 28.1493 40.0205 26.0355C40.0205 23.9217 41.0358 22.209 42.2897 22.209C43.5435 22.209 44.5588 23.9217 44.5588 26.0355Z"
          fill="#303030"
        />
        <path
          d="M43.5807 45.4571C44.3756 46.2267 45.6439 45.374 45.2356 44.3478C42.9592 38.6604 37.4019 34.646 30.9015 34.646C24.4011 34.646 19.0281 38.5303 16.6867 44.0659C16.2603 45.0741 17.5394 45.9485 18.3271 45.1861C22.8329 40.8176 32.4046 34.599 43.5807 45.4571Z"
          fill="#303030"
        />
        <path d="M19.1216 13.8213L17.0571 15.8857L23.948 22.7766L26.0124 20.7122L19.1216 13.8213Z" fill="#303030" />
        <path d="M44.8684 15.8867L42.804 13.8223L35.9131 20.7131L37.9775 22.7776L44.8684 15.8867Z" fill="#303030" />
      </svg>
    </div>
  );
};
