import React, { useState } from 'react';
import { Text } from '@fluentui/react-components';
import { Field, Form } from 'react-final-form';
import { Send24Regular, ArrowReplyDown20Filled } from '@fluentui/react-icons';

import './AddComment.css';
import { IAddCommentProps } from './interface';
import TextAreaWithMention from '../../TextAreaWithMention';

const bc = 'add-comment';

const CHARS_LIMIT = 400;

const AddComment = ({ replyClassName, storyId, uiTheme, addComment }: IAddCommentProps) => {
  const [showInput, setShowInput] = useState(false);
  const [input, setInput] = useState('');
  const charsToInputLeft = CHARS_LIMIT - input.length;
  const [mentions, setMentions] = useState<string[]>([]);
  const [pureMentionsInputValue, setPureMentionsInputValue] = useState('');

  if (showInput) {
    return (
      <div className={bc}>
        <Form
          onSubmit={(value, form) => {
            addComment({ storyId, comment: value.comment, mentions });
            setInput('');
            form.reset();
            setPureMentionsInputValue('');
          }}
          initialValues={{
            comment: '',
          }}
        >
          {({ handleSubmit }) => {
            return (
              <div className={`${bc}__input-wrapper`}>
                <div>
                  <Field
                    name="comment"
                    component={TextAreaWithMention}
                    onAddMention={setMentions}
                    placeholder="Share an encouraging reply"
                    onChangeCb={setInput}
                    maxLength={CHARS_LIMIT}
                    pureMentionsInputValue={pureMentionsInputValue}
                    setPureMentionsInputValue={setPureMentionsInputValue}
                    showBottomInfoLabelInAbsolutePosition
                    showExtraInfoMessage={false}
                  />
                </div>
                <div className={`${bc}__submit ${bc}__submit__${uiTheme}`}>
                  <Text className={`${bc}__bottom-info`}>You can tag someone in the story using “@“ before name</Text>
                  <div style={{ display: 'flex' }}>
                    <Text weight="medium" className={`${bc}__submit-chars`}>
                      {charsToInputLeft}
                    </Text>
                    {Boolean(input.trim().length) && (
                      <Send24Regular onClick={handleSubmit} className={`${bc}__submit-icon`} />
                    )}
                  </div>
                </div>
              </div>
            );
          }}
        </Form>
      </div>
    );
  }
  return (
    <div className={`${bc}__add ${bc}__add__${uiTheme}`} onClick={() => setShowInput(true)}>
      <ArrowReplyDown20Filled className={replyClassName} />
      <Text size={200}>Add Comment</Text>
    </div>
  );
};
export default AddComment;
