import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useId, Toaster, useToastController, Toast, ToastTitle, ToastBody } from '@fluentui/react-components';
import { getStatusesShowToast } from '../../store/entities/queriesStatuses/selectors';
import { resetToast } from '../../store/entities/queriesStatuses/actions';

export const GlobalToaster = ({ title, body }: { title: string; body: string }) => {
  const toasterId = useId('toaster');
  const { dispatchToast } = useToastController(toasterId);
  const showToast = useSelector(getStatusesShowToast);
  const dispatch = useDispatch();

  useEffect(() => {
    if (showToast) {
      dispatchToast(
        <Toast>
          <ToastTitle>{title}</ToastTitle>
          <ToastBody subtitle="Subtitle">{body}</ToastBody>
        </Toast>,
        { intent: 'success' },
      );

      // Reset toast state after showing
      dispatch(resetToast());
    }
  }, [showToast]);
  return (
    <>
      <Toaster toasterId={toasterId} />
    </>
  );
};
