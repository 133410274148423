import { Text } from '@fluentui/react-components';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { ECardActionType, IRegularCardBody } from '@prohabits/metaverse-v2';

import ActivityCardTemplate from 'app/components/ActivityCardTemplate';
import { IActivityCardRegularProps } from './interface';
import TextAreaWithMention from '../TextAreaWithMention';
import SubmitAndLikeDislikeButtonsGroup from 'app/components/SubmitAndLikeDislikeButtonsGroup';
import { getButtonTitle } from './helper';

import './styles.scss';

const bc = 'activity-card-regular';

const FORM_NAME = 'share-story';
const CHARS_LIMIT = 800;

export const ActivityCardRegular = ({
  postStory,
  postActivity,
  activity: { userActivityId, activityId, structure, actionType, story, likesCount, likeDislike },
  setLikeOrDislike,
}: IActivityCardRegularProps) => {
  const [mentions, setMentions] = useState<string[]>([]);
  const [pureMentionsInputValue, setPureMentionsInputValue] = useState('');
  const [body] = structure.body;
  const { title, description, question, image } = body as IRegularCardBody;
  const isShareStory = actionType === ECardActionType.committed && !story && question;
  const handleShareStory = ({ story }: { story: string }) => {
    postStory({ userActivityId, story, mentions });
    setPureMentionsInputValue('');
  };
  const handleActivity = () => {
    /* if share story card is displayed - that means we shouldn't perform any actions */
    if (!isShareStory) {
      postActivity({
        actionType: actionType === null ? ECardActionType.commit : ECardActionType.committed,
        userActivityId,
      });
    }
  };

  return (
    <div data-testid="regular-card" className={bc}>
      <ActivityCardTemplate header={'What is your opinion'} title={title} description={description} image={image}>
        <div>
          <div className={`${bc}__quote`}>
            <Text size={300} className={`${bc}__quote-text`}>
              Enjoy the little things, for one day you may look back and realize they were the big things.” — Robert
              Brualt
            </Text>
          </div>
          {isShareStory && (
            <div className={`${bc}__share-story`}>
              <Form
                onSubmit={handleShareStory}
                render={({ handleSubmit }) => {
                  return (
                    /*
                      more details about how to trigger the form outside are located here:
                      @link https://final-form.org/docs/react-final-form/faq#how-can-i-trigger-a-submit-from-outside-my-form
                    */
                    <form id={FORM_NAME} onSubmit={handleSubmit}>
                      <Field
                        name="story"
                        component={TextAreaWithMention}
                        fluid
                        placeholder="Share an encourage reply"
                        onAddMention={setMentions}
                        maxLength={CHARS_LIMIT}
                        pureMentionsInputValue={pureMentionsInputValue}
                        setPureMentionsInputValue={setPureMentionsInputValue}
                        showBottomInfoLabelInAbsolutePosition
                        showExtraInfoMessage={false}
                      />
                    </form>
                  );
                }}
              />
            </div>
          )}
          <SubmitAndLikeDislikeButtonsGroup
            form={isShareStory ? FORM_NAME : ''}
            handleSubmit={handleActivity}
            setLikeOrDislike={setLikeOrDislike}
            userActivityId={userActivityId}
            activityId={activityId}
            isLiked={likeDislike}
            likesCount={likesCount}
            buttonTitle={getButtonTitle(actionType as ECardActionType & null)}
          />
        </div>
      </ActivityCardTemplate>
    </div>
  );
};
