import { connect } from 'react-redux';
import ActivityCard from './ActivityCard';
import { setLikeOrDislike, setMarkAsViewed } from '../../store/entities/activities';

const mapDispatchToProps = (dispatch) => ({
  setMarkAsViewed: (userActivityId: number) => dispatch(setMarkAsViewed(userActivityId)),
  setLikeOrDislike: ({
    userActivityId,
    likeValue,
    previousValue,
  }: {
    userActivityId: number;
    likeValue: boolean | null;
    previousValue: boolean | null;
  }) => dispatch(setLikeOrDislike({ userActivityId, likeValue, previousValue })),
});

export default connect(null, mapDispatchToProps)(ActivityCard as any) as any;
