import * as React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import Dashboard from './app/pages/Dashboard';
import Settings from 'app/pages/Settings';
import Launch from './app/pages/Launch';
import Login from './app/pages/Login';
import SafeRoute from './app/hoc/SafeRoute';
import TeamsLogin from 'app/components/TeamsLogin';

export const router = createBrowserRouter([
  {
    path: 'settings',
    element: (
      <SafeRoute>
        <Settings />
      </SafeRoute>
    ),
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'launch',
    element: <Launch />,
  },
  { path: 'teams-login', element: <TeamsLogin /> },
  {
    path: '/',
    element: (
      <SafeRoute>
        <Dashboard />
      </SafeRoute>
    ),
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
]);
