export function parseQuery(query: string): { [key: string]: string | string[] } {
  const vars = query.split('&');
  const query_string = {};
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    const key = pair[0];
    const value = decodeURIComponent(pair[1] || '');
    // If first entry with this name
    if (typeof query_string[key] === 'undefined') {
      query_string[key] = value;
      // If second entry with this name
    } else if (typeof query_string[key] === 'string') {
      const arr = [query_string[key], value];
      query_string[key] = arr;
      // If third or later entry with this name
    } else {
      query_string[key].push(value);
    }
  }
  return query_string;
}
