import React from 'react';
import { Dropdown, Option } from '@fluentui/react-components';

import Label from '../Label';
import { IOption, ISelectProps } from './inteface';
import ErrorMessage from '../errorMessage';
import './Select.css';

const Select: React.FC<ISelectProps> = ({
  label,
  options,
  onChangeCallback,
  formatLabel,
  input,
  isControlled,
  className = '',
  meta,
}: ISelectProps) => {
  const onChange = (event: React.FormEvent<HTMLDivElement>, item: any): void => {
    const selectedItem = options?.find((option: IOption) => option.text === item.optionValue) as IOption;
    if (input) {
      input.onChange(selectedItem.key);
    }
    if (onChangeCallback) {
      onChangeCallback(selectedItem.key);
    }
  };

  const selectedOptionLabel = options?.find((option: any) => option.key === input?.value);
  const SelectProps: any = {};

  if (isControlled) {
    SelectProps.selectedOptions = [selectedOptionLabel ? selectedOptionLabel.text : ''];
  }

  return (
    <div style={{ display: 'flex', flexFlow: 'column' }}>
      {label && <Label>{formatLabel ? formatLabel(label) : label}</Label>}
      <Dropdown
        // @ts-ignore
        onOptionSelect={onChange}
        placeholder={selectedOptionLabel?.text || 'Select an option'}
        {...SelectProps}
        className={className}
      >
        {options?.map((option: any) => {
          return <Option key={option.key}>{option.text}</Option>;
        })}
      </Dropdown>
      {meta?.error && meta?.touched && <ErrorMessage message={meta.error} />}
    </div>
  );
};

export default Select;
