import { IUserCommentProps } from './UserComment/interface';
export enum ETheme {
  DARK = 'dark',
  DEFAULT = 'default',
  CONTRAST = 'contrast',
}
export interface IUserFistAndLastName {
  firstName: string;
  lastName: string;
  avatar: string | null;
  email: string;
}

export interface ICommentAndUser {
  comment: IComment;
  user: IStoryReactionUser;
}

export interface IComment {
  id: number;
  userId: number;
  storyId: number;
  comment: string;
  updatedAt: string;
  createdAt: string;
  user: IStoryReactionUser;
  reactions: IStoryReaction[];
}

export interface IStoryFeedCardProps {
  story: IStory;
  reactions: IStoryReaction[];
  user: IStoryReactionUser | IUserFistAndLastName;
  timestamp: string;
  storyIndex: number;
  comments: IUserCommentProps[];
  showCommentsBlock?: boolean;
  className?: string;
  isCardInFeed: boolean;
  isCommentReactionInMentionModal?: boolean;
  isMentionModalStoryReaction?: boolean;
  isCommentReactionInFeed?: boolean;
  // Moved from version 1
  // uiTheme: ETheme
  userId: number;
}

export interface IStory {
  activity: IStoryActivity;
  activityId: number;
  id: number;
  mentions: any[];
  story: string;
  userId: number;
}

export interface IStoryActivity {
  id: number;
  description: string;
  name: string;
  question: string;
}

export interface IStoryReaction {
  storyId: number;
  emoji: string;
  id: number;
  createdAt: string;
  updatedAt: string;
  user: IStoryReactionUser;
  userId: number;
}
export interface IStoryCommentReaction {
  commentId: number;
  storyId: number;
  emoji: string;
  id: number;
  createdAt: string;
  updatedAt: string;
  user: IStoryReactionUser;
  userId: number;
}

export interface IStoryReactionUser {
  id: number;
  avatar: string | null;
  email: string;
  firstName: string;
  lastName: string;
}
