import React, { useEffect } from 'react';
import { USA_ISO, writeLog } from '@prohabits/metaverse-v2';
import { DateTime } from 'luxon';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { ISettingsPageProps } from './interface';
import Spinner from '../../components/spinner';
import { settingsPage } from '../../validators/settingsPage';
import SettingsPageForm from './SettingsPageForm';
import './styles.css';
import { uiTheme } from './index';
import { getTimezonesWithGuess } from '../../components/constants/date';

const bc = 'settings-page-form';

const SettingsPage: React.FC<ISettingsPageProps> = ({
  isGetIdentitySuccess,
  timezone,
  notificationType,
  phone,
  putProfileSettings,
  fetchIdentity,
}) => {
  useEffect(() => {
    fetchIdentity();
  }, []);
  const handleFormSubmit = (values: any) => {
    if (!values.notificationType.sms) {
      values.phone = null;
    }
    putProfileSettings(values);
  };

  const timezones = getTimezonesWithGuess(timezone as string).map((el) => {
    // @ts-ignore
    el.key = el.value;
    // @ts-ignore
    el.text = `UTC ${DateTime.fromObject({}, { zone: el.value }).toFormat('ZZ')} ${el.name}`;
    return el;
  });
  if (!isGetIdentitySuccess) {
    return <Spinner className={`${bc}__loader`} />;
  }

  return (
    <Form
      initialValues={{
        timezone,
        notificationType: notificationType,
        phoneISO: USA_ISO,
        phone,
      }}
      onSubmit={handleFormSubmit}
      mutators={{
        ...arrayMutators,
      }}
      validate={settingsPage}
    >
      {({
        handleSubmit,
        form: {
          mutators: { push },
          reset,
        },
        values,
      }) => {
        return (
          <SettingsPageForm
            timezones={timezones}
            reset={reset}
            handleSubmit={handleSubmit}
            // uiTheme={uiTheme}
            values={values}
            phone={phone}
          />
        );
      }}
    </Form>
  );
};

export default SettingsPage;
