import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchAccessToken, fetchIdentity, fetchMstTokenBase64, logoutSuccess } from './actions';
import { IIdentity } from './interfaces';

const initialState: IIdentity = {
  id: null,
  firstName: null,
  lastName: null,
  auth: {
    accessToken: null,
    mstTokenBase64: null,
  },
  email: null,
  timezone: null,
  notificationType: null,
  phone: null,
  aadObjectId: null,
  tenantId: null,
  activity: {
    id: 0,
    hasStory: false,
    actionType: '',
    userActivityId: 0,
    story: {
      id: 0,
      userId: 0,
      activityId: 0,
      userActivityId: 0,
      story: '',
      updatedAt: '',
      createdAt: '',
      reactions: [],
      mentions: [],
    },
  },
};

const handleFetchAccessToken = (state: IIdentity, { payload }) => {
  console.log('__fetchLogin.fulfilled__', payload);
  state.auth.accessToken = payload.data.accessToken;
  return state;
};

const handleFetchIdentity = (state: IIdentity, action: PayloadAction<{ data: IIdentity }>) => {
  const { id, email, timezone, notificationType, phone, firstName, lastName, aadObjectId, tenantId } =
    action.payload.data;
  state.id = id;
  state.email = email;
  state.timezone = timezone;
  state.notificationType = notificationType;
  state.firstName = firstName;
  state.lastName = lastName;
  state.phone = phone;
  state.aadObjectId = aadObjectId;
  state.tenantId = tenantId;
};

const handleLogoutSuccess = (state: IIdentity) => {
  state.auth.accessToken = null;
  return state;
};

const handleFetchMstTokenBase64 = (
  state: IIdentity,
  { payload }: { payload: { data: { mstTokenBase64: string } } },
) => {
  state.auth.mstTokenBase64 = payload.data.mstTokenBase64;
  return state;
};

const handleFetchMstTokenBase64Rejected = (state: IIdentity) => {
  state.auth.mstTokenBase64 = false;
  return state;
};

export const identity = createSlice({
  name: 'identity',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchIdentity.fulfilled.toString()]: handleFetchIdentity,
    [fetchAccessToken.fulfilled.toString()]: handleFetchAccessToken,
    [logoutSuccess.toString()]: handleLogoutSuccess,
    [fetchMstTokenBase64.fulfilled.toString()]: handleFetchMstTokenBase64,
    [fetchMstTokenBase64.rejected.toString()]: handleFetchMstTokenBase64Rejected,
    // This is necessary so that if the user is not invited and has installed APP MST, we must show the text
    [fetchAccessToken.rejected.toString()]: handleFetchMstTokenBase64Rejected,
  },
});

export const identitySlice = identity.reducer;
