import axios from 'axios';
import { store } from './store';
import { writeLog } from '@prohabits/metaverse-v2';

const axiosInstance = axios.create({
  baseURL: process.env.API,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    // 'X-Request-Token': uuid(),
    // source: EActionSource.MST,
    // app_version: process.env.REACT_APP_VERSION,
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.identity.auth.accessToken;
    writeLog({ message: '__axiosInstance.interceptors.request__', data: { token } });
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log('__ERROR_axiosInstance.interceptors.request___', error);
  },
);

export default axiosInstance;
