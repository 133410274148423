import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';
import './App.css';

const Root = () => {
  return <RouterProvider router={router} />;
};

export default Root;
