import { connect } from 'react-redux';
import { writeLog } from '@prohabits/metaverse-v2';
import { ETheme } from '../../containers/StoryFeedCard/interface';

import {
  getIdentityTimezone,
  getIdentityNotificationType,
  getIdentityEmail,
  getIdentityPhone,
  putProfileSettings,
  fetchIdentity,
} from 'app/store/entities/identity';
import { getQueriesStatusesGetIdentitySuccess } from '../../store/entities/queriesStatuses/selectors';
import SettingsPage from './SettingsPage';
import { RootState } from '../../store';
// import { getUITheme } from '../../store/entities/ui/selectors'

export const uiTheme = ETheme.DEFAULT;
const mapStateToProps = (state: RootState) => {
  return {
    timezone: getIdentityTimezone(state),
    notificationType: getIdentityNotificationType(state),
    email: getIdentityEmail(state),
    phone: getIdentityPhone(state),
    isGetIdentitySuccess: getQueriesStatusesGetIdentitySuccess(state),
    // uiTheme: getUITheme(state),
  };
};

const mapDispatchToProps = {
  putProfileSettings,
  fetchIdentity,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage as any);
