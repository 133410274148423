import React from 'react';
import { Checkbox } from '@fluentui/react-components';
import { EUserNotificationType } from '@prohabits/metaverse-v2';

const labels = {
  email: 'Email',
  sms: 'SMS',
  mst: 'Microsoft Teams',
} as any;
const UserNotificationTypes = ({ input, phone }: any) => {
  const handleChange = (event: any, { name, checked }: { name: string; checked: boolean }) => {
    const newValues = { ...input.value };
    newValues[event.target.name] = checked;
    input.onChange(newValues);
  };
  return (
    <div>
      <>
        <Checkbox
          name={EUserNotificationType.EMAIL}
          // @ts-ignore
          onChange={handleChange}
          checked={input.value.email}
          label={labels.email}
        />
        <Checkbox
          name={EUserNotificationType.SMS}
          // @ts-ignore
          onChange={handleChange}
          checked={input.value.sms}
          label={labels.sms}
        />
        <Checkbox
          name={EUserNotificationType.MST}
          // @ts-ignore
          onChange={handleChange}
          checked={input.value.mst}
          label={labels.mst}
        />
      </>
    </div>
  );
};

export default UserNotificationTypes;
