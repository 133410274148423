import React, { useState } from 'react';
import { Popover, PopoverSurface, PopoverTrigger } from '@fluentui/react-components';

import { EMOJIS } from './constants';
import RegullaryAlignedIcons from './RegullaryAlignedIcons';
import EmptyReactionsList from './EmptyReactionsList';

import './Reactions.css';

import { IReactionsProps } from './interface';
import { IStoryReaction } from '../StoryFeedCard/interface';

const bc = 'reaction-icon';

const Reactions = ({
  reactions,
  isOwnUserReaction,
  storyId,
  storyIndex,
  commentIndex,
  commentId,
  isCommentReactionInFeed,
  isCommentReactionInMentionModal,
  isMentionModalStoryReaction,
  stories,
  userId,
  storyForMentionModal,
  updateStoryReaction,
  updateCommentReaction,
  setCommentReaction,
  setStoryReaction,
}: IReactionsProps) => {
  const [open, setOpen] = useState(false);

  const isReactionOnComment = isCommentReactionInFeed || isCommentReactionInMentionModal;

  if (!reactions.length) {
    return (
      <EmptyReactionsList
        isOwnUserReaction={isOwnUserReaction}
        storyId={storyId}
        userId={userId}
        commentId={commentId}
        isReactionOnComment={isReactionOnComment}
      />
    );
  }

  const handleSetReaction = ({ emoji }: { emoji: string }) => {
    let newReactions = [];
    if (isCommentReactionInFeed) {
      newReactions = stories[storyIndex].activitiesUsersStoriesComments[commentIndex as number].reactions;
    } else if (isMentionModalStoryReaction) {
      newReactions = storyForMentionModal.reactions;
    } else if (isCommentReactionInMentionModal) {
      newReactions = storyForMentionModal.activitiesUsersStoriesComments[commentIndex as number].reactions;
    } else {
      newReactions = stories[storyIndex].reactions;
    }

    let userReaction: any = {};
    const isUserAlreadyReactedToCommentOrStory = newReactions.some((reaction: IStoryReaction) => {
      if (reaction.userId === userId) {
        userReaction = reaction;
        return true;
      }
    });

    if (isUserAlreadyReactedToCommentOrStory) {
      isReactionOnComment
        ? commentId && updateCommentReaction({ id: userReaction.id, emoji, storyId, commentId })
        : updateStoryReaction({ id: userReaction.id, emoji, storyId });
    } else {
      isReactionOnComment
        ? commentId && setCommentReaction({ commentId, emoji, storyId })
        : setStoryReaction({ storyId, emoji });
    }

    setOpen(false);
  };

  const countedEmojis: any = {};
  reactions.forEach((reaction: any) => {
    if (reaction.emoji in countedEmojis) {
      countedEmojis[reaction.emoji]++;
    } else {
      countedEmojis[reaction.emoji] = 1;
    }
  });
  const reactionsKeys = Object.keys(countedEmojis);

  return (
    <div>
      <Popover
        open={open}
        // do not allow set reaction to own stories or comments
        onOpenChange={() => setOpen((prev) => (isOwnUserReaction ? false : !prev))}
        positioning="above-end"
      >
        <div>
          <PopoverTrigger>
            <div>
              <div>
                <RegullaryAlignedIcons
                  iconClassName={`${bc}__icon`}
                  reactionsKeys={reactionsKeys}
                  countedEmojis={countedEmojis}
                  reactions={reactions}
                />
              </div>
            </div>
          </PopoverTrigger>
        </div>
        <PopoverSurface>
          <div>
            {EMOJIS.map((emoji) => {
              const Icon = emoji.icon;
              return (
                <Icon
                  onClick={() => {
                    handleSetReaction({ emoji: emoji.name });
                  }}
                  className={`${bc}__icon`}
                  key={emoji.name}
                />
              );
            })}
          </div>
        </PopoverSurface>
      </Popover>
    </div>
  );
};
export default Reactions;
