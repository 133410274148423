import React from 'react';
import { Input } from '@fluentui/react-components';
import Label from '../Label';
import ErrorMessage from '../errorMessage';

const DEFAULT_MAX_CHARS_LENGTH = 256;

const InputDefault = ({ input, meta, label, onChangeCb, maxLength = DEFAULT_MAX_CHARS_LENGTH, ...inputProps }: any) => {
  const { value, onChange } = input;
  const handleChange = (e: any) => {
    if (e.target.value.length > maxLength) {
      return;
    }
    onChange(e);
    if (onChangeCb) {
      onChangeCb(e.target.value);
    }
  };
  return (
    <div>
      {label ? (
        <div>
          <Label>{label} </Label>
        </div>
      ) : null}
      <Input style={{ width: '100%' }} onChange={handleChange} value={value} {...inputProps} />
      {meta.error && meta.touched && <ErrorMessage message={meta.error} />}
    </div>
  );
};

export default InputDefault;
