import { createSelector } from 'reselect';
import { RootState } from '../..';
import { IActivitiesStoriesState } from './interfaces';
import { canLoadMore } from './helper';

export const getActivitiesStoriesRoot = (state: RootState): IActivitiesStoriesState => state.activitiesStories;

export const getStories = createSelector(getActivitiesStoriesRoot, (activitiesStories) => activitiesStories.stories);
export const getStoryForMentionModal = createSelector(
  getActivitiesStoriesRoot,
  (activitiesStories) => activitiesStories.storyForMentionModal.story,
);

export const getUsersForMentions = createSelector(
  getActivitiesStoriesRoot,
  (activitiesStories) => activitiesStories.usersForMentions,
);

export const getActivitiesStoriesPagination = createSelector(getActivitiesStoriesRoot, (activitiesStories) => {
  return activitiesStories.pagination;
});

export const getActivitiesStoriesPaginationDetails = createSelector(getActivitiesStoriesPagination, (pagination) => {
  return canLoadMore(pagination);
});

export const getActivitiesStoriesPending = createSelector(
  getActivitiesStoriesRoot,
  (activitiesStories) => activitiesStories.pending,
);

export const getActivitiesStoriesLoadMorePending = createSelector(
  getActivitiesStoriesRoot,
  (activitiesStories) => activitiesStories.loadMorePending,
);
