import React, { useState } from 'react';
import { getUserNameOrEmail } from '@prohabits/metaverse-v2';
import { Text } from '@fluentui/react-components';
import { CaretDown16Filled } from '@fluentui/react-icons';

import UserCommentBlock from '../UserComment';

import './ShowCommentsAccordeon.css';
import { IUserCommentProps } from '../UserComment/interface';
import { ETheme } from '../interface';

const bc = 'show-comments-accordion';

const ShowCommentsAccordeon = ({
  comments,
  storyIndex,
  storyId,
  isCommentReactionInMentionModal,
  isCommentReactionInFeed,
  uiTheme,
}: {
  comments: IUserCommentProps[];
  storyIndex: number;
  storyId: number;
  isCommentReactionInMentionModal?: boolean;
  isCommentReactionInFeed?: boolean;
  uiTheme: ETheme;
}) => {
  const [showComments, setShowComments] = useState(false);
  let commentsTitle = '';
  if (comments.length === 1) {
    commentsTitle = `1 reply from ${comments.map(({ user: { firstName, lastName, email } }: any) =>
      getUserNameOrEmail({ firstName, lastName, email }),
    )}`;
  } else if (comments.length === 2 || comments.length === 3) {
    commentsTitle = `${comments.length} replies from ${comments
      .map(({ user: { firstName, lastName, email } }: any) => getUserNameOrEmail({ firstName, lastName, email }))
      .join(', ')}`;
  } else {
    const moreRepliesQuantity = comments.length - 3;
    commentsTitle = `3 replies from ${comments
      .slice(0, 3)
      .map(({ user: { firstName, lastName, email } }: any) => getUserNameOrEmail({ firstName, lastName, email }))
      .join(', ')} and ${moreRepliesQuantity} more...`;
  }

  return (
    <div className={`${bc} ${bc}__${uiTheme}`}>
      {showComments ? (
        <>
          <div className={`${bc}__title-wrapper`} onClick={() => setShowComments((prev) => !prev)}>
            <CaretDown16Filled className={`${bc}__title`} /> <Text className={`${bc}__title`}>Collapse All </Text>{' '}
          </div>
          <div>
            {comments.map(({ comment, user, createdAt, reactions, id }: any, idx) => {
              return (
                <UserCommentBlock
                  key={`${comment + user + createdAt}`}
                  comment={comment}
                  user={user}
                  createdAt={createdAt}
                  reactions={reactions}
                  storyIndex={storyIndex}
                  storyId={storyId}
                  commentIdx={idx}
                  commentId={id}
                  isCommentReactionInMentionModal={isCommentReactionInMentionModal}
                  isCommentReactionInFeed={isCommentReactionInFeed}
                />
              );
            })}
          </div>
        </>
      ) : (
        <Text className={`${bc}__title`} onClick={() => setShowComments((prev) => !prev)}>
          {commentsTitle}
        </Text>
      )}
    </div>
  );
};
export default ShowCommentsAccordeon;
