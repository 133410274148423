import { createSelector } from 'reselect';

import { RootState } from '../../index';
import { IQueriesStatusesState } from './slices';

export const getQueriesStatuses = (state: RootState): IQueriesStatusesState => state.queriesStatuses;

export const getQueriesStatusesPutProfileSettingsSuccess = createSelector(
  getQueriesStatuses,
  (queriesStatuses) => queriesStatuses.putProfileSettings.success,
);

export const getQueriesStatusesGetIdentitySuccess = createSelector(
  getQueriesStatuses,
  (queriesStatuses) => queriesStatuses.getIdentity.success,
);

export const getIsMstTokenWasFetched = createSelector(getQueriesStatuses, (queriesStatuses) => {
  return queriesStatuses.isMstTokenWasFetched;
});

export const getAccessTokenWasFetched = createSelector(getQueriesStatuses, (queriesStatuses) => {
  return queriesStatuses.accessTokenWasFetched;
});
export const getStatusesShowToast = createSelector(getQueriesStatuses, (queriesStatuses) => queriesStatuses.showToast);
