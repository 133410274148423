import { IIdentity } from './interfaces';
import { RootState } from 'app/store';
import { createSelector } from '@reduxjs/toolkit';

export const getIdentity = (state: RootState): IIdentity => state.identity;
export const getAccessToken = createSelector(getIdentity, (identity) => identity.auth.accessToken);
export const getMstTokenBase64 = createSelector(getIdentity, (identity) => identity.auth.mstTokenBase64);
export const getIdentityUserId = createSelector(getIdentity, (identity) => identity.id);
export const getIdentityTimezone = createSelector(getIdentity, (identity) => identity.timezone);
export const getIdentityActivity = createSelector(getIdentity, (identity) => identity.activity);
export const getIdentityActivityId = createSelector(getIdentityActivity, (activity) => activity?.id);
export const getIdentityNotificationType = createSelector(getIdentity, (identity) => identity.notificationType);
export const getIdentityEmail = createSelector(getIdentity, (identity) => identity.email);
export const getIdentityPhone = createSelector(getIdentity, (identity) => identity.phone);
export const getIdentityFirstName = createSelector(getIdentity, (identity) => identity.firstName);
export const getIdentityLastName = createSelector(getIdentity, (identity) => identity.lastName);
export const getIdentityTenantId = createSelector(getIdentity, (identity) => identity.tenantId);
export const getIdentityAadObjectId = createSelector(getIdentity, (identity) => identity.aadObjectId);
