import { connect } from 'react-redux';
import TeamsLogin from './TeamsLogin';
import {
  fetchMstTokenBase64,
  getAccessToken,
  fetchAccessToken,
  getMstTokenBase64,
  logoutSuccess,
} from 'app/store/entities/identity';
import { setIsMicrosoftTeams } from 'app/store/entities/techInfo';
import { getIsMstTokenWasFetched } from 'app/store/entities/queriesStatuses/selectors';

const mapStateToProps = (state) => ({
  accessToken: getAccessToken(state),
  mstTokenBase64: getMstTokenBase64(state),
  isMstTokenWasFetched: getIsMstTokenWasFetched(state),
});
const mapDispatchToProps = {
  fetchMstTokenBase64,
  setIsMicrosoftTeams,
  fetchAccessToken,
  logoutSuccess,
};
export default connect(mapStateToProps, mapDispatchToProps)(TeamsLogin as any);
