import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { FluentProvider, teamsDarkTheme, teamsHighContrastTheme, teamsLightTheme } from '@fluentui/react-components';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
console.log('process.env.NODE_ENV', process.env.NODE_ENV);
console.log('process.env.API_VERSION', process.env.API_VERSION);
console.log('process.env.API', process.env.API);

import { store, persistor, AppDispatch } from './app/store';
import App from './app/Root';
import { app } from '@microsoft/teams-js';
import { isWithinMst } from 'app/helpers/routing';
import { useEffect, useState } from 'react';
import { getUITheme } from './app/store/entities/ui/selectors';
import { setUITheme } from './app/store/entities/ui/actions';
import { ETheme } from './app/store/entities/ui/interfaces';

Sentry.init({
  dsn: 'https://776fba4ffb3247df71c9195f92d82082@o124946.ingest.sentry.io/4506620994387968',
  environment: process.env.NODE_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
async function initializeAndGetContext() {
  try {
    await app.initialize();
    return await app.getContext();
  } catch (error) {
    throw error;
  }
}
const teamsTheme = teamsLightTheme;
const setTheme = (theme: ETheme | undefined, setCurrentTh: (theme: any) => void) => {
  console.log('__setTheme__', theme);
  switch (theme) {
    case ETheme.DARK:
      setCurrentTh(teamsDarkTheme);
      break;
    case ETheme.CONTRAST:
      setCurrentTh(teamsHighContrastTheme);
      break;
    default:
      setCurrentTh(teamsTheme);
      break;
  }
};
export default function RenderApp() {
  const uiTheme = useSelector(getUITheme);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    console.log('__useEffect_uiTheme__', uiTheme);
    setTheme(uiTheme, setCurrentTheme);
  }, [uiTheme]);
  const [currentTheme, setCurrentTheme] = useState(teamsTheme);
  if (isWithinMst) {
    initializeAndGetContext()
      .then((context) => {
        const th = context.app.theme as ETheme;
        dispatch(setUITheme(th));
        console.log('Context received successfully:', context);
      })
      .catch((error) => {
        console.error('Error getting context:', error);
      });
  }
  return (
    <FluentProvider theme={currentTheme}>
      <App />
    </FluentProvider>
  );
}
const container = document.getElementById('docroot');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <RenderApp />
    </PersistGate>
  </Provider>,
);
