import { connect } from 'react-redux';
import { Header } from './Header';
import { RootState } from '../../store';
import { getIdentityEmail, getIdentityFirstName, getIdentityLastName } from '../../store/entities/identity';

const mapStateToProps = (state: RootState) => ({
  firstName: getIdentityFirstName(state),
  lastName: getIdentityLastName(state),
  email: getIdentityEmail(state),
});

export default connect(mapStateToProps)(Header as any) as any;
