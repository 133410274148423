import React from 'react';
import { ICommonCard } from './interface';

export const Sad = ({ svgClassName, wrapperClassName, width = 63, height = 63 }: ICommonCard) => {
  return (
    <div className={wrapperClassName}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        className={svgClassName}
      >
        <path
          d="M31.2348 61.6332C48.2452 61.6332 62.0348 47.8436 62.0348 30.8332C62.0348 13.8228 48.2452 0.0332031 31.2348 0.0332031C14.2245 0.0332031 0.434814 13.8228 0.434814 30.8332C0.434814 47.8436 14.2245 61.6332 31.2348 61.6332Z"
          fill="#A6A7DC"
        />
        <path
          d="M31.2355 47.6024C34.3067 47.6024 36.7964 43.3817 36.7964 38.1752C36.7964 32.9687 34.3067 28.748 31.2355 28.748C28.1643 28.748 25.6746 32.9687 25.6746 38.1752C25.6746 43.3817 28.1643 47.6024 31.2355 47.6024Z"
          fill="#303030"
        />
        <path
          d="M21.7117 24.7056L13.6071 29.869L13.8058 56.2282C16.2159 57.8867 18.8753 59.2092 21.7117 60.1306V24.7092V24.7056Z"
          fill="#73C2FF"
        />
        <path
          d="M40.7703 24.7056L48.8749 29.869L48.6762 56.2282C46.2661 57.8867 43.6067 59.2092 40.7703 60.1306V24.7092V24.7056Z"
          fill="#73C2FF"
        />
        <path
          d="M14.6107 30.7843C14.2205 30.7843 13.8375 30.6109 13.5809 30.2821C13.1365 29.7148 13.2413 28.8946 13.8086 28.4501L18.2494 24.9886L13.6568 22.2894C13.0353 21.9245 12.8294 21.1223 13.1943 20.5008C13.5593 19.8793 14.3614 19.6734 14.9829 20.0383L21.2304 23.7131C21.6025 23.9335 21.8446 24.3201 21.8699 24.7537C21.8988 25.1837 21.7109 25.6028 21.3713 25.8702L15.4129 30.5133C15.1744 30.6976 14.8926 30.788 14.6107 30.788V30.7843Z"
          fill="#303030"
        />
        <path
          d="M47.8318 30.7845C47.55 30.7845 47.2681 30.6942 47.0296 30.5099L41.0713 25.8668C40.7316 25.5994 40.5437 25.1839 40.5726 24.7503C40.6015 24.3203 40.84 23.9301 41.2122 23.7096L47.4596 20.0349C48.0811 19.6699 48.8797 19.8759 49.2482 20.4974C49.6132 21.1189 49.4072 21.9174 48.7857 22.286L44.1932 24.9852L48.6339 28.4467C49.2012 28.8912 49.306 29.7114 48.8616 30.2787C48.605 30.6075 48.2184 30.7809 47.8318 30.7809V30.7845Z"
          fill="#303030"
        />
      </svg>
    </div>
  );
};
