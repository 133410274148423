import React, { useEffect } from 'react';
import { ECardType, IActivityCard } from '@prohabits/metaverse-v2';
import { makeStyles, tokens } from '@fluentui/react-components';
import cn from 'classnames';

import ActivityCardRegular from '../ActivityCardRegular';
import ActivityCardWizzard from '../ActivityCardWizzard';
import { ActivityCardSimpleWithForm } from '../ActivityCardSimple';

const bc = 'activity-card';

const useStyles = makeStyles({
  background: { backgroundColor: tokens.colorNeutralForegroundInverted },
});

const ActivityCard = ({
  activity,
  setMarkAsViewed,
  setLikeOrDislike,
}: {
  activity: IActivityCard;
  setMarkAsViewed: (userActivityId: number) => void;
  setLikeOrDislike: (params: { userActivityId: number; likeValue: boolean | null }) => void;
}) => {
  const classes = useStyles();
  useEffect(() => {
    if (activity.userActivityId && !activity.openedAt) setMarkAsViewed(activity.userActivityId);
  }, [activity]);

  const { structure } = activity;
  const { type } = structure;

  if (type === ECardType.regular) {
    return (
      <div data-testid="activity-card-regular" className={bc}>
        <ActivityCardRegular activity={activity} setLikeOrDislike={setLikeOrDislike} />
      </div>
    );
  }
  if (type === ECardType.simple) {
    return (
      <div data-testid="activity-card-simple" className={bc}>
        <ActivityCardSimpleWithForm activity={activity} setLikeOrDislike={setLikeOrDislike} />
      </div>
    );
  }
  if (type === ECardType.wizzard) {
    return (
      <div data-testid="activity-card-wizzard" className={cn(bc, classes.background)}>
        <ActivityCardWizzard activity={activity} />
      </div>
    );
  }
  return null;
};

export default ActivityCard;
