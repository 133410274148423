import { Popover, PopoverTrigger, PopoverSurface } from '@fluentui/react-components';
import React from 'react';
import { EMOJIS_BY_ICONS_NAMES } from '../constants';
import ReactedUsersList from '../ReactedUsersList';
import { IRegullaryAlignedIconsProps } from './interface';
import './RegullaryAlignedIcons.css';

/* this component is responsible for rendering the users who were reacted on story or comment after hovering on icons*/
const bc = 'regullary-aligned-items';

export default ({ reactionsKeys, countedEmojis, iconClassName, reactions }: IRegullaryAlignedIconsProps) => {
  return (
    <Popover openOnHover positioning="below-end">
      <PopoverTrigger>
        <div className={bc}>
          {reactionsKeys.map((iconName: string) => {
            // @ts-ignore
            const Icon = EMOJIS_BY_ICONS_NAMES[iconName];
            return (
              // @ts-ignore
              <span key={iconName} style={{ display: 'flex', alignItems: 'center' }}>
                <Icon className={iconClassName} /> {countedEmojis[iconName as any]}
              </span>
            );
          })}
        </div>
      </PopoverTrigger>
      <PopoverSurface>
        <ReactedUsersList reactions={reactions} />
      </PopoverSurface>
    </Popover>
  );
};
