import { createSlice } from '@reduxjs/toolkit';
import { setIsMicrosoftTeams } from './actions';

export interface ITechInfo {
  isMicrosoftTeams: boolean;
}
const initialState = {
  isMicrosoftTeams: false,
};

const handleIsMicrosoft = (state: ITechInfo) => {
  state.isMicrosoftTeams = true;
};

export const techInfo = createSlice({
  name: 'techInfo',
  initialState,
  reducers: {},
  extraReducers: {
    [setIsMicrosoftTeams.toString()]: handleIsMicrosoft,
  },
});

export const techInfoSlice = techInfo.reducer;
