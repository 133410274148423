import { createSlice } from '@reduxjs/toolkit';
import { setMoodCheck } from './actions';
export interface IMoodCheckStatusesState {
  setMoodCheck: {
    success: boolean;
  };
}

const initialState: IMoodCheckStatusesState = {
  setMoodCheck: {
    success: false,
  },
};

const handleSetMoodCheckFulfilled = (state: IMoodCheckStatusesState) => {
  state.setMoodCheck.success = true;
  return state;
};

export const moodCheckStatuses = createSlice({
  name: 'moodCheckStatuses',
  initialState,
  reducers: {},
  extraReducers: {
    [setMoodCheck.fulfilled.toString()]: handleSetMoodCheckFulfilled,
  },
});

export const moodCheckStatusesSlice = moodCheckStatuses.reducer;
