import { IActivities } from './interface';
import { RootState } from 'app/store';
import { createSelector } from '@reduxjs/toolkit';
import { IActivityCard, ECardActionType, ECardType } from '@prohabits/metaverse-v2';
import { IActivitiesState } from './slices';

export const getActivities = (state: RootState): IActivitiesState => state.activities;

export const getActivitiesList = createSelector(getActivities, (activities): IActivityCard[] | [] => activities.list);

export const getFilteredActivitiesList = createSelector(getActivitiesList, (activities): IActivityCard[] | [] => {
  const filtered = activities.filter((activity: IActivityCard) => {
    const structure = activity.structure;
    const completedWithoutQuestion =
      structure.type === ECardType.regular &&
      activity.actionType === ECardActionType.committed &&
      !structure.body[0].question;

    if (activity.storyId || activity.answer || completedWithoutQuestion) {
      return false;
    }
    return true;
  });
  return filtered;
});

export const getMountedActivityId = createSelector(getActivities, (activities) => {
  return activities.mountedActivityId;
});

export const getMountedActivityLikesCount = createSelector(
  getActivities,
  getMountedActivityId,
  (activities, mountedActivityId) => {
    const activity = activities.list.find((activity) => {
      return activity.activityId === mountedActivityId;
    });
    console.log({ activity, likes: activity?.likesCount });
    return activity?.likesCount;
  },
);
