import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../api';

export const fetchIdentity = createAsyncThunk('identity/fetchIdentity', async () => {
  console.log('__action_fetchIdentity__');
  const res = await axios.get(`me`);
  return res.data;
});

export const fetchLogin = createAsyncThunk('identity/fetchLogin', async (payload: { uid: string }) => {
  console.log('__action_fetchLogin__', payload);
  const res = (await axios.get(`login/${payload.uid}`)) as { data: {}; errors: any[] };
  return res.data;
});

export const fetchMstTokenBase64 = createAsyncThunk(
  'identity/fetchMstTokenBase64',
  async (payload: { uid: string }) => {
    const res = (await axios.get(`login-mst/${payload.uid}`)) as { data: {}; errors: any[] };
    return res.data;
  },
);

export const fetchAccessToken = createAsyncThunk(
  'identity/fetchAccessToken',
  async (payload: { email: string | null; aadObjectId: string | null; token: string }) => {
    console.log('__action_fetchAccessToken__', payload);
    const res = (await axios.post(`login`, payload)) as { data: { accessToken: string }; errors: any[] };
    return res.data;
  },
);

export const putProfileSettings = createAsyncThunk('identity/putProfileSettings', async (data: any) => {
  const res = await axios.put('/me', data);
  console.log(res.data);
  return res.data;
});

export const logoutSuccess = createAction('identity/logoutSuccess');
