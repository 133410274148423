import React from 'react';
import { Checkbox } from '@fluentui/react-components';

import './Checkbox.scss';
import { Field } from 'react-final-form';
import { ICheckboxProps } from './interface';

const bc = 'checkbox';

export default ({ checkboxes, fieldName }: ICheckboxProps) => {
  return (
    <div data-testid="simple-card-checkbox" className={bc}>
      <Field name={fieldName}>
        {({ input }) => {
          return checkboxes.map((checkbox) => {
            const { label, value } = checkbox;
            return (
              <Checkbox
                name={value}
                checked={input.value[value]}
                onChange={(e, { checked }) => input.onChange({ ...input.value, [e.target.name]: checked })}
                label={label}
              />
            );
          });
        }}
      </Field>
    </div>
  );
};
