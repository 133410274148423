import React, { useEffect, useState } from 'react';
const { DateTime } = require('luxon');
import { DismissRegular } from '@fluentui/react-icons';
import { Button, Text } from '@fluentui/react-components';
import { Angry } from 'app/icons/Angry';
import { Happy } from 'app/icons/Happy';
import { Sad } from 'app/icons/Sad';
import { Content } from 'app/icons/Content';
import { Tired } from 'app/icons/Tired';

import './styles.scss';
import { EMoodCheckEmoji } from '@prohabits/metaverse-v2';

import { IMoodCheckProps } from './inteface';

const bc = 'mood-check';

export const MoodCheck: React.FC<IMoodCheckProps> = ({ moodCheckStatuses, updateMoodCheck, setIsVisible }) => {
  useEffect(() => {
    if (moodCheckStatuses) {
      setIsVisible(false);
    }
  }, [moodCheckStatuses]);

  const handleSetMoodCheck = (emoji) => {
    updateMoodCheck(emoji);
  };

  return (
    <div data-testid="mood-check-component" className={bc}>
      <div className={`${bc}__block`}>
        <div className={`${bc}__date`}>{DateTime.local().toFormat('LLL dd')}</div>
        <Text className={`${bc}__text`} size={300}>
          How are you today?
        </Text>
        <div className={`${bc}__line`} />
        <div className={`${bc}__icons-wrapper`}>
          <div
            className={`${bc}__icon-wrapper`}
            onClick={() => {
              handleSetMoodCheck(EMoodCheckEmoji.angry);
            }}
          >
            <Angry svgClassName={`${bc}__icon-svg`} width={32} height={32} />
          </div>
          <div
            className={`${bc}__icon-wrapper`}
            onClick={() => {
              handleSetMoodCheck(EMoodCheckEmoji.sad);
            }}
          >
            <Sad svgClassName={`${bc}__icon-svg`} width={32} height={32} />
          </div>
          <div
            className={`${bc}__icon-wrapper`}
            onClick={() => {
              handleSetMoodCheck(EMoodCheckEmoji.tired);
            }}
          >
            <Tired svgClassName={`${bc}__icon-svg`} width={32} height={32} />
          </div>
          <div
            className={`${bc}__icon-wrapper`}
            onClick={() => {
              handleSetMoodCheck(EMoodCheckEmoji.content);
            }}
          >
            <Content svgClassName={`${bc}__icon-svg`} width={32} height={32} />
          </div>
          <div
            className={`${bc}__icon-wrapper`}
            onClick={() => {
              handleSetMoodCheck(EMoodCheckEmoji.happy);
            }}
          >
            <Happy svgClassName={`${bc}__icon-svg`} width={32} height={32} />
          </div>
        </div>
      </div>
      <Button
        data-testid="dismiss-button"
        appearance="transparent"
        icon={<DismissRegular />}
        onClick={() => {
          setIsVisible(false);
        }}
      />
    </div>
  );
};
