import React, { useState, useEffect } from 'react';
import { Button } from '@fluentui/react-components';
import { Form } from 'react-final-form';

import { ActivityCardSimple } from '../ActivityCardSimple/ActivityCardSimple';
import './styles.scss';
import { cardAnswerParser } from 'app/helpers/cardAnswerParser';
import { IActivityCardWizzardProps } from './interface';

const bc = 'activity-card-wizzard';

const createCardSteps = (cards: any): [{ status: string; stepTitle: string; value: number }] => {
  return cards.map((card, idx) => {
    const status = idx === 0 ? 'doing' : 'todo';
    return { ...card, status, stepTitle: `${idx + 1} step` };
  });
};

export const ActivityCardWizzard = (props: IActivityCardWizzardProps) => {
  const {
    activity: { structure, userActivityId },
  } = props;
  const body = structure.body;
  const [activeIndex, setActiveIndex] = useState(0);
  const [headerItems, setHeaderItems] = useState(createCardSteps(body));

  useEffect(() => {
    const updatedItems = headerItems.map((item, idx) => {
      if (idx === activeIndex) {
        item.status = 'doing';
      }
      if (idx < activeIndex) {
        item.status = 'done';
      }
      if (idx > activeIndex) {
        item.status = 'todo';
      }
      return item;
    });
    // @ts-ignore
    setHeaderItems(updatedItems);
  }, [activeIndex]);

  const handleNextStep = () => {
    setActiveIndex((prev) => (prev + 1 === headerItems.length ? prev : prev + 1));
  };

  const onSubmit = async (formValues) => {
    const answer = cardAnswerParser({ activity: structure, formValues });
    props.postActivity({ userActivityId, answer });
  };

  const isLastStep = activeIndex + 1 === headerItems.length;
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => {
        const currentInputValue = values[`step-${activeIndex}`];
        const disabled = currentInputValue === undefined;

        return (
          <form className={bc} onSubmit={handleSubmit}>
            <div>
              <div className={`${bc}__multi-step`}>
                <ol>
                  {headerItems.map((item) => {
                    return (
                      <li key={item.value} className={item.status}>
                        {item.stepTitle}
                      </li>
                    );
                  })}
                </ol>
              </div>
              <div className={`${bc}__steps-counter`}>{`${activeIndex + 1} of ${headerItems.length} steps`}</div>
              <div className={`${bc}__card-body`}>
                {/* @ts-ignore */}
                <ActivityCardSimple {...body[activeIndex]} fieldName={`step-${activeIndex}`} isWithinWizzard />
                <div className={`${bc}__buttons`}>
                  <Button
                    onClick={() => {
                      setActiveIndex((prev) => (prev - 1 < 0 ? 0 : prev - 1));
                    }}
                  >
                    Previous
                  </Button>
                  <Button disabled={disabled} onClick={isLastStep ? handleSubmit : handleNextStep} appearance="primary">
                    {isLastStep ? 'Submit' : 'Next'}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        );
      }}
    />
  );
};
