import { connect } from 'react-redux';
import Login from './Login';
import { fetchLogin, getAccessToken } from '../../store/entities/identity';
import { RootState } from '../../store';

const mapStateToProps = (state: RootState) => ({
  accessToken: getAccessToken(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchLogin: (data: { uid: string }) => {
    console.log('__mapDispatchToProps_fetchLogin_', data);
    dispatch(fetchLogin(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
