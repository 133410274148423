import React from 'react';
import { ICommonCard } from './interface';

export const Content = ({ svgClassName, wrapperClassName, width = 63, height = 63 }: ICommonCard) => {
  return (
    <div className={wrapperClassName}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        className={svgClassName}
      >
        <path
          d="M31.2348 61.808C48.2452 61.808 62.0348 48.0184 62.0348 31.008C62.0348 13.9976 48.2452 0.208008 31.2348 0.208008C14.2244 0.208008 0.434814 13.9976 0.434814 31.008C0.434814 48.0184 14.2244 61.808 31.2348 61.808Z"
          fill="#FFC163"
        />
        <path
          d="M18.6172 34.1829C17.8223 33.4132 16.554 34.266 16.9623 35.2921C19.2387 40.9795 24.796 44.9939 31.2964 44.9939C37.7967 44.9939 43.1698 41.1096 45.5112 35.574C45.9376 34.5659 44.6585 33.6914 43.8708 34.4539C39.3649 38.8224 29.7932 45.0409 18.6172 34.1829Z"
          fill="#303030"
        />
        <path
          d="M39.1823 24.2691C40.0531 26.4479 42.1344 27.8571 44.4795 27.8571C46.8245 27.8571 48.8371 26.4913 49.7333 24.3738C49.9031 23.9692 49.7694 23.5103 49.4044 23.2609C49.0395 23.0116 48.5661 23.0514 48.2482 23.3585C46.5391 25.017 43.8363 26.3467 40.671 23.2682C40.494 23.0947 40.2627 23.0044 40.0315 23.0044C39.8544 23.0044 39.6774 23.055 39.5184 23.1634C39.1534 23.4055 39.0161 23.8608 39.1787 24.2691H39.1823Z"
          fill="#303030"
        />
        <path
          d="M12.7205 24.2689C13.5913 26.4477 15.6726 27.8569 18.0176 27.8569C20.3627 27.8569 22.3753 26.4911 23.2714 24.3773C23.4448 23.9726 23.3075 23.5137 22.9462 23.2644C22.5812 23.015 22.1079 23.0548 21.7899 23.3619C20.0808 25.0204 17.3817 26.3501 14.2128 23.2716C14.0357 23.0981 13.8045 23.0078 13.5732 23.0078C13.3962 23.0078 13.2191 23.0584 13.0601 23.1668C12.6952 23.4089 12.5579 23.8642 12.7205 24.2725V24.2689Z"
          fill="#303030"
        />
      </svg>
    </div>
  );
};
