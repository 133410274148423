import React from 'react';
import { DateTime } from 'luxon';
import { Button, Text } from '@fluentui/react-components';
import { getUserNameOrEmail } from '@prohabits/metaverse-v2';
import { Board24Regular } from '@fluentui/react-icons';
import { NavLink } from 'react-router-dom';

import { generateTimeGreeting } from './Header.helpers';

import './styles.scss';

const bc = 'header';

export const Header = ({
  firstName,
  lastName,
  email,
  showDashboardLink,
}: {
  firstName: string | null;
  lastName: string | null;
  email: string;
  showDashboardLink?: boolean;
}) => {
  const fullName = getUserNameOrEmail({ firstName, lastName, email });
  return (
    <div className={bc}>
      <div className={`${bc}__block`}>
        <div className={`${bc}__date`}>{DateTime.local().toFormat('LLL dd')}</div>
        <Text className={`${bc}__text`} weight="medium" size={300}>
          {generateTimeGreeting()}, {fullName}
        </Text>
      </div>
      {Boolean(showDashboardLink) && (
        <NavLink to="/dashboard">
          <Button className={`${bc}__button`} icon={<Board24Regular />}>
            <Text size={300}>Dashboard</Text>
          </Button>
        </NavLink>
      )}
    </div>
  );
};
