import React from 'react';
import { ICommonCard } from './interface';

export const Tired = ({ svgClassName, wrapperClassName, width = 63, height = 63 }: ICommonCard) => {
  return (
    <div className={wrapperClassName}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        className={svgClassName}
      >
        <path
          d="M30.9597 62.4335C47.97 62.4335 61.7597 48.6439 61.7597 31.6335C61.7597 14.6231 47.97 0.833496 30.9597 0.833496C13.9493 0.833496 0.159668 14.6231 0.159668 31.6335C0.159668 48.6439 13.9493 62.4335 30.9597 62.4335Z"
          fill="#73C2FF"
        />
        <path
          d="M37.4582 44.0114C35.7058 42.4938 33.3318 41.6374 30.9542 41.6808C28.6164 41.688 26.2641 42.5444 24.4936 44.0222C23.6011 44.7774 22.8531 45.6988 22.3328 46.6961C21.7836 47.7765 21.4981 48.8677 21.4873 49.9336C21.4873 50.1902 21.6463 50.4178 21.8848 50.5009C22.1269 50.584 22.3943 50.5045 22.5496 50.3022C23.1892 49.4783 23.7926 48.8207 24.396 48.3004C25.0464 47.7475 25.7366 47.2887 26.4484 46.9309C27.8684 46.2227 29.433 45.8433 30.9795 45.8361C32.5658 45.8253 34.0689 46.1794 35.4564 46.902C36.8837 47.6319 38.1953 48.7773 39.3516 50.2986C39.4672 50.4503 39.6443 50.5334 39.8249 50.5334C39.89 50.5334 39.955 50.5226 40.0201 50.5009C40.2621 50.4178 40.4247 50.1865 40.4211 49.93C40.3886 47.7837 39.2793 45.5687 37.4546 44.0078L37.4582 44.0114Z"
          fill="#303030"
        />
        <path
          d="M53.1222 36.6183C52.7175 36.4991 52.3273 36.3907 51.9262 36.2787C51.0915 36.0438 50.3038 35.8234 49.545 35.5596C48.4032 35.173 47.377 34.7358 46.4086 34.2263C45.3897 33.6879 44.4647 33.0845 43.5758 32.3871C42.6038 31.6319 41.7041 30.7503 40.873 29.912C40.7863 29.8253 40.6526 29.8 40.5406 29.8506C40.4286 29.9011 40.3563 30.0168 40.3635 30.1396C40.4286 31.444 40.9417 32.7918 41.8522 34.0384C42.6833 35.1622 43.7926 36.1052 45.0681 36.7737C46.2641 37.4024 47.6046 37.789 48.9488 37.8902L49.1042 37.9011C49.2921 37.9119 49.4764 37.9155 49.657 37.9155C50.9506 37.9155 52.1249 37.6698 53.1619 37.1784C53.2739 37.1242 53.3426 37.0086 53.3318 36.8857C53.3209 36.7629 53.2378 36.6581 53.1186 36.6219L53.1222 36.6183Z"
          fill="#303030"
        />
        <path
          d="M21.3738 29.8506C21.2618 29.8 21.1281 29.8253 21.0414 29.912C20.2103 30.7539 19.3106 31.6355 18.3386 32.3907C17.4534 33.0881 16.5248 33.6879 15.5058 34.2299C14.5374 34.7394 13.5112 35.1766 12.3694 35.5632C11.6106 35.827 10.8229 36.0474 9.9738 36.2859C9.59078 36.3943 9.19693 36.5027 8.79585 36.6219C8.67661 36.6581 8.5935 36.7629 8.58266 36.8857C8.57182 37.0086 8.64048 37.1278 8.75249 37.1784C9.78951 37.6662 10.9675 37.9155 12.2574 37.9155C12.4381 37.9155 12.6224 37.9119 12.8102 37.9011L12.9656 37.8902C14.3098 37.789 15.6539 37.4024 16.8463 36.7737C18.1182 36.1088 19.2311 35.1622 20.0622 34.0384C20.9728 32.7954 21.4895 31.4477 21.5509 30.1396C21.5581 30.0168 21.4859 29.9011 21.3738 29.8506Z"
          fill="#303030"
        />
      </svg>
    </div>
  );
};
