import { ECardActionType } from '@prohabits/metaverse-v2';

export const getButtonTitle = (actionType: ECardActionType | null) => {
  if (actionType === null) {
    return 'I Commit';
  }
  if (actionType === ECardActionType.commit) {
    return 'I Did';
  }
  return 'Submit';
};
