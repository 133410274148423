import { connect } from 'react-redux';

import { ActivityCardSimple } from './ActivityCardSimple';
import { ActivityCardSimpleForm } from './ActivityCardSimpleForm';
import { postActivity } from 'app/store/entities/activities';

export default ActivityCardSimple as any;

const mapDispatchToProps = { postActivity };

export const ActivityCardSimpleWithForm = connect<{ userActivityId: number }, any, any>(
  null,
  mapDispatchToProps,
)(ActivityCardSimpleForm as any);
