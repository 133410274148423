import { ETheme } from '../StoryFeedCard/interface';
import { connect } from 'react-redux';
import { getStories, getStoryForMentionModal } from '../../store/entities/activitiesStories/selectors';
import { getIdentityUserId } from '../../store/entities/identity';
import { RootState } from '../../store';
import Reactions from './Reactions';
import {
  setCommentReaction,
  setStoryReaction,
  updateCommentReaction,
  updateStoryReaction,
} from '../../store/entities/activitiesStories/actions';
import {
  ISetStoryCommentReaction,
  ISetStoryReaction,
  IUpdateStoryCommentReaction,
  IUpdateStoryReaction,
} from './interface';
export const uiTheme = ETheme.DEFAULT;
export const userId = 179106;
const mapStateToProps = (state: RootState) => ({
  stories: getStories(state),
  userId: getIdentityUserId(state),
  storyForMentionModal: getStoryForMentionModal(state),
  // uiTheme: getUITheme(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateCommentReaction: ({ id, emoji, storyId, commentId }: IUpdateStoryCommentReaction) => {
    dispatch(updateCommentReaction({ id, emoji, storyId, commentId }));
  },
  updateStoryReaction: ({ id, emoji, storyId }: IUpdateStoryReaction) => {
    dispatch(updateStoryReaction({ id, emoji, storyId }));
  },
  setStoryReaction: ({ emoji, storyId }: ISetStoryReaction) => {
    dispatch(setStoryReaction({ storyId, emoji }));
  },
  setCommentReaction: ({ commentId, emoji, storyId }: ISetStoryCommentReaction) => {
    dispatch(setCommentReaction({ commentId, emoji, storyId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Reactions as any) as any;
