import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { app } from '@microsoft/teams-js';
import { isWithinMst } from 'app/helpers/routing';
import * as Sentry from '@sentry/react';
import { IActivityCard, fromBase64 } from '@prohabits/metaverse-v2';

import StoryFeed from '../../containers/StoryFeed';
import MoodCheck from '../../components/MoodCheck';
import CardsCarousel from 'app/components/CardsCarousel';
import ProHabitsPausedCard from '../../components/ProHabitsPausedCard';
import Header from '../../components/Header';
import MentionModal from 'app/components/Modals/MentionModal';
import { IActivityCompletedStory } from 'app/containers/StoryFeed/interface';

import './styles.scss';

const bc = 'dashboard';

const Dashboard = (props: {
  fetchIdentity: () => void;
  fetchActivities: () => void;
  activities: IActivityCard[] | [];
  isMicrosoftTeams: boolean;
  putProfileSettings: (arg: { tenantId: string; aadObjectId: string }) => void;
  aadObjectId: string | null;
  tenantId: string | null;
  fetchStoryForMention: (args: { commentId?: number; storyId?: number }) => void;
  storyForMentionModal: IActivityCompletedStory;
  mountedActivityId: number;
  getLikesCountByActivityId: (activityId: number) => void;
  mountedActivityLikesCount: number;
}) => {
  const {
    activities,
    isMicrosoftTeams,
    aadObjectId,
    tenantId,
    putProfileSettings,
    fetchStoryForMention,
    fetchIdentity,
    fetchActivities,
    storyForMentionModal,
    mountedActivityId,
    getLikesCountByActivityId,
    mountedActivityLikesCount,
  } = props;
  console.log({ mountedActivityId });
  const [isVisible, setIsVisible] = useState(true);
  const [openMentionModal, setOpenMentionModal] = useState(true);
  async function getContext() {
    return await app.getContext();
  }
  useEffect(() => {
    fetchIdentity();
    fetchActivities();
    if (isWithinMst) {
      let contextUser = {};
      getContext()
        .then((context) => {
          contextUser = context.user!;
          console.log('Context received successfully:', context);
          if (!tenantId || !aadObjectId) {
            putProfileSettings({ tenantId: context.user?.tenant?.id!, aadObjectId: context.user?.id! });
          }
          const {
            page: { subPageId },
          } = context;
          if (subPageId) {
            const encoded = JSON.parse(fromBase64(subPageId) as string);
            const {
              data: { commentId, storyId },
            } = encoded;
            fetchStoryForMention({ commentId, storyId });
          }
        })
        .catch((error) => {
          console.log('Context received error:', error);
          Sentry.withScope((scope) => {
            scope.setExtra('contextUser', contextUser);
            Sentry.captureException(error);
          });
        });
    }
  }, [isWithinMst]);

  useEffect(() => {
    if (mountedActivityId && !mountedActivityLikesCount) {
      getLikesCountByActivityId(mountedActivityId);
    }
  }, [mountedActivityId, mountedActivityLikesCount]);
  return (
    <div className={bc}>
      {isVisible && (
        <section>
          <MoodCheck setIsVisible={setIsVisible} />
        </section>
      )}
      {!isMicrosoftTeams && !isVisible && (
        <header className={`${bc}__header`}>
          <Header />
        </header>
      )}

      <section className={`${bc}__content`}>
        <div className={cn(`${bc}__left`, { [`${bc}__cards`]: activities.length })}>
          {activities.length ? <CardsCarousel activities={activities} /> : <ProHabitsPausedCard />}
        </div>

        <div className={`${bc}__right`}>
          <StoryFeed />
        </div>
      </section>
      {/* please use following place for the modals */}
      <MentionModal open={openMentionModal} onClose={setOpenMentionModal} story={storyForMentionModal} />
    </div>
  );
};

export default Dashboard;
