import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../api';
import { ECardActionType } from '@prohabits/metaverse-v2';

export const postActivity = createAsyncThunk(
  'activities/post-activity',
  async (payload: { answer?: string; userActivityId: number; actionType?: ECardActionType }) => {
    const { answer, userActivityId, actionType } = payload;
    const { data } = await axios.post(`users-activities`, { answer, userActivityId, actionType });
    return data;
  },
);

export const postStory = createAsyncThunk(
  'activities/post-story',
  async (payload: { story: string; userActivityId: string; mentions: string[] }) => {
    const { userActivityId, story, mentions } = payload;
    const { data } = await axios.post(`stories`, {
      userActivityId,
      story,
      mentions: mentions.map((id) => parseInt(id)),
    });
    return data;
  },
);

export const fetchActivities = createAsyncThunk('activities/fetch-activities', async () => {
  const { data } = await axios.get('users-activities');
  return data;
});

export const setMarkAsViewed = createAsyncThunk('tracking', async (userActivityId: number) => {
  const { data } = await axios.post('tracking', { userActivityId });
  return data;
});

export const setLikeOrDislike = createAsyncThunk(
  'activities/like-or-dislike',
  async ({
    userActivityId,
    likeValue,
    previousValue,
  }: {
    userActivityId: number;
    likeValue: boolean | null;
    previousValue: boolean | null;
  }) => {
    const { data } = await axios.post('users-activities/like-or-dislike', { userActivityId, likeValue, previousValue });
    return data;
  },
);

export const getLikesCountByActivityId = createAsyncThunk('activities/likes-count', async (activityId: number) => {
  const { data } = await axios.get(`users-activities/likes-count/${activityId}`);
  return data;
});

export const setMountedActivity = createAction('activities/set-mounted-activity', (activityId) => {
  return { payload: { data: { activityId } } };
});
