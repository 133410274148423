import React, { ReactNode } from 'react';

import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from '@fluentui/react-components';

export const Modal = ({
  open,
  title,
  confirmBtnTitle,
  confirmParams,
  onClose,
  onConfirm,
  children,
}: {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  title?: string;
  confirmBtnTitle?: string;
  onConfirm?: (args: any) => void;
  confirmParams?: any;
}) => {
  const isShowConfirmBtn = Boolean(confirmBtnTitle && onConfirm);
  return (
    <Dialog
      open={open}
      onOpenChange={(e, data) => {
        // hide a modal if the user clicked on the backdrop (click outside the modal window)
        if (data.type === 'backdropClick') {
          onClose();
        }
      }}
    >
      <DialogSurface>
        <DialogBody>
          {Boolean(title) && <DialogTitle>{title}</DialogTitle>}
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={onClose}>
                Close
              </Button>
            </DialogTrigger>
            {isShowConfirmBtn && (
              <Button appearance="primary" onClick={onConfirm?.bind(null, confirmParams)}>
                {confirmBtnTitle || 'Confirm'}
              </Button>
            )}
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
