import React, { useState } from 'react';
import { Popover, PopoverSurface, PopoverTrigger } from '@fluentui/react-components';
import { ThumbLike20Regular } from '@fluentui/react-icons';

import { EMOJIS } from '../constants';
import { IEmptyReactionsListProps, IHandleSetReaction } from './interface';
import { ISetStoryCommentReaction } from '../interface';

const bc = 'reaction-icon';
const EmptyReactionsList = ({
  isOwnUserReaction,
  storyId,
  commentId,
  isReactionOnComment,
  setCommentReaction,
  setStoryReaction,
}: IEmptyReactionsListProps) => {
  const [open, setOpen] = useState(false);
  const handleSetReaction = ({ storyId, emoji }: IHandleSetReaction) => {
    if (isReactionOnComment) {
      setCommentReaction({ commentId, emoji, storyId } as ISetStoryCommentReaction);
    } else {
      setStoryReaction({ storyId, emoji });
    }
    setOpen(false);
  };

  // if we don't have reactions and it's user's own story or comment - hide like button
  if (isOwnUserReaction) {
    return null;
  }

  return (
    <Popover open={open} onOpenChange={() => setOpen((prev) => !prev)} positioning="above-end">
      <PopoverTrigger>
        <div>
          <ThumbLike20Regular className={`${bc}__icon`} />
        </div>
      </PopoverTrigger>
      <PopoverSurface>
        <div>
          {EMOJIS.map((emoji) => {
            const Icon = emoji.icon;
            return (
              <Icon
                onClick={() => {
                  handleSetReaction({ storyId, emoji: emoji.name });
                }}
                className={`${bc}__icon`}
                key={emoji.name}
              />
            );
          })}
        </div>
      </PopoverSurface>
    </Popover>
  );
};

export default EmptyReactionsList;
