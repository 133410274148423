import React from 'react';
import { ICommonCard } from './interface';
export const Happy = ({ svgClassName, wrapperClassName, width = 63, height = 63 }: ICommonCard) => {
  return (
    <div className={wrapperClassName}>
      <svg
        className={svgClassName}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
      >
        <path
          d="M31.6 62.3998C48.6104 62.3998 62.4 48.6102 62.4 31.5998C62.4 14.5894 48.6104 0.799805 31.6 0.799805C14.5897 0.799805 0.800049 14.5894 0.800049 31.5998C0.800049 48.6102 14.5897 62.3998 31.6 62.3998Z"
          fill="#F5CE45"
        />
        <path
          d="M22.5264 24.1258C22.5264 26.2396 21.511 27.9523 20.2572 27.9523C19.0034 27.9523 17.988 26.2396 17.988 24.1258C17.988 22.012 19.0034 20.2993 20.2572 20.2993C21.511 20.2993 22.5264 22.012 22.5264 24.1258Z"
          fill="#303030"
        />
        <path
          d="M45.2295 24.1258C45.2295 26.2396 44.2142 27.9523 42.9603 27.9523C41.7065 27.9523 40.6912 26.2396 40.6912 24.1258C40.6912 22.012 41.7065 20.2993 42.9603 20.2993C44.2142 20.2993 45.2295 22.012 45.2295 24.1258Z"
          fill="#303030"
        />
        <path
          d="M10.9041 34.5127C12.0784 44.9046 20.8913 52.984 31.6012 52.984C42.3111 52.984 51.124 44.9046 52.2947 34.5127H10.9041Z"
          fill="#303030"
        />
        <path
          d="M17.3018 47.2959C21.0307 50.8189 26.0605 52.9833 31.5961 52.9833C37.1317 52.9833 41.7278 51.0032 45.4026 47.744L45.2111 47.2959C31.3142 40.5173 17.4897 47.202 17.3018 47.2959Z"
          fill="#F16D24"
        />
      </svg>
    </div>
  );
};
