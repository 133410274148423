import { connect } from 'react-redux';
import SafeRoute from './SafeRoute';
import { RootState } from '../../store';
import { getAccessToken } from '../../store/entities/identity';
import { getIsMstTokenWasFetched } from 'app/store/entities/queriesStatuses/selectors';

const mapStateToProps = (state: RootState) => ({
  accessToken: getAccessToken(state),
  isMstTokenWasFetched: getIsMstTokenWasFetched(state),
});

export default connect(mapStateToProps)(SafeRoute);
