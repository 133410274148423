import React from 'react';
import { Text, Avatar } from '@fluentui/react-components';
import { DateTime } from 'luxon';
import { getUserFullName } from '@prohabits/metaverse-v2';
import cn from 'classnames';
import Reactions from '../Reactions';
import { IStoryFeedCardProps } from './interface';
import AddComment from './AddComment';
import ShowCommentsAccordeon from './ShowCommentsAccordeon';
import './StoryFeedCard.css';
import { uiTheme } from './index';

const bc = 'story-feed-card';

const StoryFeedCard = ({
  story,
  reactions,
  user,
  timestamp,
  storyIndex,
  comments,
  showCommentsBlock = true,
  className,
  isCardInFeed,
  isCommentReactionInMentionModal,
  isMentionModalStoryReaction,
  isCommentReactionInFeed,
  userId,
}: IStoryFeedCardProps) => {
  const isOwnUserReaction = story.userId === userId;

  const { firstName, lastName, avatar, email } = user;
  const fullName = getUserFullName(firstName, lastName, email);
  const { story: storyText, activity } = story;

  const isCommentsExist = comments?.length > 0;

  return (
    <div className={cn(bc, { [className as string]: className })}>
      <div className={cn(`${bc}__wrapper`, { [`${bc}__offset`]: isCardInFeed })}>
        <div className={cn(`${bc}__avatar-block`, { [`${bc}__avatar-block--feed`]: isCardInFeed })}>
          <div className={`${bc}__show-on-mobile ${bc}__show-on-mobile__${uiTheme}`}>
            <Avatar size={40} image={{ src: avatar || '' }} name={fullName} />
            <div className={`${bc}__name-date`}>
              <Text weight="bold" size={300} className={`${bc}__user-name-mobile`}>
                {fullName}
              </Text>
              <Text size={300}>{DateTime.fromISO(timestamp).toFormat('hh:mm a')}</Text>
            </div>
            <div className={`${bc}__reactions-mobile`}>
              {' '}
              <Reactions
                storyId={story.id}
                reactions={reactions}
                isOwnUserReaction={isOwnUserReaction}
                storyIndex={storyIndex}
                isMentionModalStoryReaction={isMentionModalStoryReaction}
              />
            </div>
          </div>

          <div className={`${bc}__show-on-desktop`}>
            <Avatar size={40} image={{ src: avatar || '' }} name={fullName} />
          </div>
        </div>
        <div className={`${bc}__story-block ${bc}__story-block__${uiTheme}`}>
          <div className={cn(`${bc}__story-block-content`, { [`${bc}__story-block-content--feed`]: isCardInFeed })}>
            <div className={`${bc}__show-on-desktop_name-comment`}>
              <div>
                <div className={`${bc}__show-on-desktop`}>
                  <Text weight="semibold" size={200} className={`${bc}__user-name`}>
                    {fullName}
                  </Text>
                  <Text size={200}>{DateTime.fromISO(timestamp).toFormat('hh:mm a')}</Text>
                </div>
              </div>
              <div className={`${bc}__reactions ${bc}__show-on-desktop`}>
                <div>
                  <Reactions
                    storyId={story.id}
                    reactions={reactions}
                    isOwnUserReaction={isOwnUserReaction}
                    storyIndex={storyIndex}
                    isMentionModalStoryReaction={isMentionModalStoryReaction}
                  />
                </div>
              </div>
            </div>
            <Text weight="semibold" size={200}>
              {activity.name}
            </Text>
            <div className={`${bc}__description-wrapper`}>
              <Text>
                <Text size={200} className={`${bc}__question-label`}>
                  <div dangerouslySetInnerHTML={{ __html: activity.question }} />
                </Text>
                {storyText}
              </Text>
            </div>
          </div>
          {isCommentsExist && showCommentsBlock && (
            <ShowCommentsAccordeon
              comments={comments}
              storyIndex={storyIndex}
              storyId={story.id}
              isCommentReactionInMentionModal={isCommentReactionInMentionModal}
              isCommentReactionInFeed={isCommentReactionInFeed}
              uiTheme={uiTheme}
            />
          )}
          {showCommentsBlock && <AddComment storyId={story.id} replyClassName={`${bc}__reply`} uiTheme={uiTheme} />}
        </div>
      </div>
    </div>
  );
};
export default StoryFeedCard;
