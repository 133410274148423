import React from 'react';
import { Spinner } from '@fluentui/react-components';

const SpinnerComponent = ({ styles = {}, className = '' }: { styles?: object; className?: string }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...styles,
      }}
      className={className}
    >
      <Spinner size="huge" />
    </div>
  );
};

export default SpinnerComponent;
