import React, { useState } from 'react';
import { Button, Text } from '@fluentui/react-components';
import { Navigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { ILoginProps } from './interface';
import Input from '../../components/Input';

import './styles.scss';
import LoginModal from 'app/components/Modals/LoginModal';

const bc = 'login';

const Login = ({ fetchLogin, accessToken }: ILoginProps) => {
  console.log('__Login_accessToken_');
  if (accessToken) {
    return <Navigate to="/" />;
  }
  const validate = (values: string) => {
    const email = values;
    if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return { uid: 'Inform a valid email.' };
    }
    return undefined;
  };

  const [openModal, setOpenModal] = useState(false);

  return (
    <div className={`${bc}__login-wrapper`}>
      <LoginModal open={openModal} onClose={() => setOpenModal(false)} />
      <Text className={`${bc}__title-block`} weight="regular" size={800}>
        Login
      </Text>
      <div className={bc}>
        <Form
          onSubmit={fetchLogin}
          initialValues={{ uid: '' }}
          validate={({ uid }: { uid: string }) => {
            return validate(uid);
          }}
          render={({ handleSubmit, submitting, values, valid }) => (
            <form
              onSubmit={(e) => {
                handleSubmit(e);
                setOpenModal(true);
              }}
              className={`${bc}__login-block`}
            >
              <div className={`${bc}__login-block`}>
                <Text className={`${bc}__title`} weight="medium" size={600}>
                  Login using your email
                </Text>
                <Text className={`${bc}__subtitle`} weight="regular" size={400}>
                  You’ll receive an email with a magic link.
                </Text>
                <div style={{ width: '100%' }}>
                  <Field
                    className={`${bc}__input`}
                    name="uid"
                    component={Input}
                    fluid
                    placeholder="Inform Your Email"
                  />
                </div>
                <Button
                  disabled={submitting || !values.uid || !valid}
                  type="submit"
                  appearance="primary"
                  className={`${bc}__button`}
                >
                  <Text weight="regular" size={400}>
                    EMAIL ME A MAGIC LINK
                  </Text>
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default Login;
