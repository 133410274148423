import AddComment from './AddComment';
import { connect } from 'react-redux';
import { addComment } from '../../../store/entities/activitiesStories/actions';
import { IAddComment } from './interface';

const mapDispatchToProps = (dispatch) => ({
  addComment: ({ storyId, comment, mentions }: IAddComment) => {
    dispatch(addComment({ storyId, comment, mentions }));
  },
});

export default connect(null, mapDispatchToProps)(AddComment as any) as any;
