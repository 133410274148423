import { connect } from 'react-redux';
import EmptyReactionsList from './EmptyReactionsList';
import { setCommentReaction, setStoryReaction } from '../../../store/entities/activitiesStories/actions';
import { ISetStoryCommentReaction, ISetStoryReaction } from '../interface';

const mapDispatchToProps = (dispatch) => ({
  setStoryReaction: ({ emoji, storyId }: ISetStoryReaction) => {
    dispatch(setStoryReaction({ storyId, emoji }));
  },
  setCommentReaction: ({ commentId, emoji, storyId }: ISetStoryCommentReaction) => {
    dispatch(setCommentReaction({ commentId, emoji, storyId }));
  },
});

export default connect(null, mapDispatchToProps)(EmptyReactionsList as any) as any;
