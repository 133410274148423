import { Avatar } from '@fluentui/react-components';
import { DateTime } from 'luxon';
import React from 'react';
import { getUserFullName } from '@prohabits/metaverse-v2';
import { Text } from '@fluentui/react-components';
import { IUserCommentProps } from './interface';

import './UserComment.css';
import Reactions from '../../Reactions';
const bc = 'user-comment';

const UserCommentBlock = ({
  user,
  comment,
  createdAt,
  reactions,
  storyIndex,
  storyId,
  commentIdx,
  commentId,
  isCommentReactionInFeed,
  isCommentReactionInMentionModal,
  userId,
}: Omit<IUserCommentProps, 'id'>) => {
  const isOwnUserComment = user.id === userId;
  const { firstName, lastName, avatar, email } = user;
  const fullName = getUserFullName(firstName, lastName, email);
  return (
    <div className={bc}>
      <div className={`${bc}__avatar-wrapper`}>
        <Avatar image={{ src: avatar || '' }} name={fullName} size={40} aria-label="Guest" />
      </div>
      <div>
        <div className={`${bc}__title-wrapper`}>
          <Text weight="bold" size={200} className={`${bc}__name`}>
            {fullName}
          </Text>
          <Text className={`${bc}__date`} size={200}>
            {DateTime.fromISO(createdAt).toFormat('hh:mm a')}
          </Text>
          <Reactions
            storyId={storyId}
            reactions={reactions}
            isOwnUserReaction={isOwnUserComment}
            storyIndex={storyIndex}
            commentIndex={commentIdx}
            commentId={commentId}
            isCommentReactionInFeed={isCommentReactionInFeed}
            isCommentReactionInMentionModal={isCommentReactionInMentionModal}
          />
        </div>
        <div>
          <Text size={200}>{comment}</Text>
        </div>
      </div>
    </div>
  );
};

export default UserCommentBlock;
