import { connect } from 'react-redux';
import StoryFeedCard from './StoryFeedCard';
import { getIdentityUserId } from '../../store/entities/identity';
import { ETheme } from './interface';
import { RootState } from '../../store';
export const uiTheme = ETheme.DEFAULT;
const mapStateToProps = (state: RootState) => ({
  userId: getIdentityUserId(state),
});

export default connect(mapStateToProps)(StoryFeedCard as any) as any;
