const CLOUDFRONT_URL_PART = 'mst.prohabits';

const userAgent = window.navigator.userAgent.toLowerCase();
const isTeamsUserAgent = userAgent.includes('teamsmobile');

const getEmbeddedBy = (): string => {
  return window.location !== window.parent.location ? document.referrer : document.location.href;
};

const isEmbeddedByMsTeams = (): boolean => {
  const getEmbeddedByRes = getEmbeddedBy();
  console.log({ getEmbeddedByRes });
  return window !== window.top && getEmbeddedByRes.startsWith('https://teams.microsoft.com');
};

const isEmbeddedByMsTeamsNative = (): boolean => {
  return navigator.userAgent.toLocaleLowerCase().includes('electron') && isEmbeddedByMsTeams();
};

export const isEmbedded = window.parent !== window;
console.log({ isEmbedded });
export const isWithinMst =
  location.href.includes(CLOUDFRONT_URL_PART) ||
  isEmbeddedByMsTeams() ||
  isEmbeddedByMsTeamsNative() ||
  isTeamsUserAgent ||
  isEmbedded;

/* @TODO: Remove logs within this file right after this ticket will be closed:
https://github.com/prohabits/prohabits-ui-v2/issues/128
 */
