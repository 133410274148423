import React, { useEffect, useRef, useState } from 'react';
import { Button, Text, makeStyles, tokens } from '@fluentui/react-components';
import cn from 'classnames';
import {
  ChevronLeft24Regular,
  ChevronRight24Regular,
  Settings24Regular,
  SignOut24Regular,
} from '@fluentui/react-icons';
import { NavLink } from 'react-router-dom';
import ActivityCard from 'app/containers/ActivityCard';
import { ICardCarouselProps } from './interface';
import UsersWhoCompletedActivity from '../UsersWhoCompletedActivity/UsersWhoCompletedActivity';
import { isWithinMst } from '../../helpers/routing';

import './styles.scss';

const bc = 'cards-carousel';

enum EAnimationDirections {
  left = 'left',
  right = 'right',
}

const getFakeCardsCountForRender = (cardsCount: number) => {
  if (cardsCount >= 3) {
    return 2;
  }
  if (cardsCount > 1) {
    return cardsCount - 1;
  }
  return 0;
};
const useStyles = makeStyles({
  background: { backgroundColor: tokens.colorNeutralForegroundInverted },
});

const CardsCarousel = ({ activities, logoutSuccess }: ICardCarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [height, setHeight] = useState(0);
  const [animationStarted, setAnimationStarted] = useState(false);
  const [animationDirection, setAnimationDirection] = useState<EAnimationDirections | null>(null);
  const ref = useRef<any>(null);
  const classes = useStyles();

  const handleRight = () => {
    setActiveIndex((prev) => {
      if (prev + 1 > activitiesLength - 1) {
        return 0;
      }
      return prev + 1;
    });
  };

  const handleLeft = () => {
    setActiveIndex((prev) => {
      if (prev - 1 < 0) {
        return activitiesLength - 1;
      }
      return prev - 1;
    });
  };

  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setHeight(ref.current.clientHeight);
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, [activeIndex]);
  useEffect(() => {
    /*In case when activities length has changed - show the first element in the array */
    setActiveIndex(0);
  }, [activities.length]);

  useEffect(() => {
    if (animationStarted) {
      /*
        change active index only after the timeout in order to do not display
        the next card content until the animation will be finished
      */
      setTimeout(() => {
        animationDirection === EAnimationDirections.right ? handleRight() : handleLeft();
        setAnimationStarted(false);
      }, 400);
    }
  }, [animationStarted]);

  const activitiesLength = activities.length;
  const isActivitiesListEmpty = activitiesLength === 0;
  const activityToRender = activities[activeIndex];
  const singleActivity = activities.length === 1;
  const multipleActivities = activities.length > 1;

  if (!activityToRender) {
    return null;
  }

  return (
    <div className={bc}>
      <div className={`${bc}__header`}>
        <Text className={`${bc}__title`} size={400}>
          You have {activitiesLength} activities left today!
        </Text>
        <div className={`${bc}__cards-navigation`}>
          {multipleActivities && (
            <>
              <Button
                icon={<ChevronLeft24Regular />}
                onClick={() => {
                  setAnimationDirection(EAnimationDirections.left);
                  setAnimationStarted(true);
                }}
              />
              <Text className={`${bc}__count`}>
                {activeIndex + 1} of {activitiesLength}
              </Text>
              <Button
                icon={<ChevronRight24Regular />}
                onClick={() => {
                  setAnimationDirection(EAnimationDirections.right);
                  setAnimationStarted(true);
                }}
              />
            </>
          )}

          <NavLink
            className={cn(`${bc}__settings`, { [`${bc}__settings-single-activity`]: singleActivity })}
            to="/settings"
          >
            <Button icon={<Settings24Regular />}>
              <Text size={300}>Settings</Text>
            </Button>
          </NavLink>
          {!isWithinMst && (
            <NavLink
              className={cn(`${bc}__settings`, { [`${bc}__settings-single-activity`]: singleActivity })}
              to="/login"
            >
              <Button icon={<SignOut24Regular />} onClick={() => logoutSuccess()}>
                <Text size={300}>Logout</Text>
              </Button>
            </NavLink>
          )}
        </div>
      </div>
      <div className={`${bc}__cards`}>
        <div
          ref={ref}
          key={activeIndex}
          className={cn(`${bc}__card`, `${bc}__main-card`, classes.background, {
            [`${bc}__slide-out-right`]: animationStarted && animationDirection === EAnimationDirections.right,
            [`${bc}__slide-out-left`]: animationStarted && animationDirection === EAnimationDirections.left,
          })}
        >
          <ActivityCard activity={activityToRender} />
          <div className={`${bc}__users-who-completed`}>
            {activityToRender.bottomMessage && (
              <UsersWhoCompletedActivity bottomMessage={activityToRender.bottomMessage} />
            )}
          </div>
        </div>
        {!isActivitiesListEmpty &&
          new Array(getFakeCardsCountForRender(activitiesLength)).fill(null).map((_, idx) => {
            return (
              <div
                key={idx}
                style={{
                  minHeight: `${height}px`,
                  zIndex: activitiesLength - idx,
                  left: `${(idx + 1) * 8}px`,
                  top: `${(idx + 1) * 8}px`,
                }}
                className={cn(`${bc}__card`, `${bc}__fake-card`, classes.background)}
              />
            );
          })}
        {multipleActivities && (
          <div className={`${bc}__carousel-dots`}>
            {activities.map((_, idx) => {
              return (
                <div
                  className={cn(`${bc}__carousel-dot`, { [`${bc}__carousel-dot-active`]: idx === activeIndex })}
                  key={idx}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardsCarousel;
