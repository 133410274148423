import React, { useEffect } from 'react';
import { Text, Button } from '@fluentui/react-components';
import { ArrowClockwise20Regular } from '@fluentui/react-icons';

import StoryFeedCard from '../StoryFeedCard';
import GlobalSpinner from '../../components/spinner';
import LoadMore from './LoadMore';
import { IStoryFeedProps } from './interface';
import './StoryFeed.scss';

const bc = 'story-feed';

const StoryFeed = ({
  loadMoreActivitiesStories,
  stories,
  pending,
  loadMorePending,
  pagination,
  paginationDetails,
  // height,
  runFunctions, // uiTheme,
}: IStoryFeedProps) => {
  const { nextPage, canLoadMore } = paginationDetails;
  useEffect(() => {
    runFunctions();
  }, []);
  return (
    <div className={bc}>
      <section className={`${bc}__header`}>
        <Text className={`${bc}__header-title`} weight="semibold" size={400}>
          Community Feed
        </Text>
        {(stories.length && (
          <div className={`${bc}__refresh`}>
            <Button
              disabled={pending || loadMorePending}
              className={`${bc}__refresh-group`}
              onClick={() => runFunctions()}
            >
              <ArrowClockwise20Regular />
              <Text className={`${bc}__refresh-btn`} size={300}>
                Refresh
              </Text>
            </Button>
          </div>
        )) ||
          null}
      </section>
      {pending ? (
        <GlobalSpinner styles={{ margin: '20px 0', position: 'relative' }} />
      ) : stories.length ? (
        <>
          <div className={`${bc}__cards-wrapper`}>
            {stories.map((story: any, idx: number) => {
              const { activityUserStory, reactions, user, timestamp, activitiesUsersStoriesComments } = story;
              return (
                <StoryFeedCard
                  key={activityUserStory.id}
                  story={activityUserStory}
                  reactions={reactions}
                  user={user}
                  timestamp={timestamp}
                  storyIndex={idx}
                  comments={activitiesUsersStoriesComments}
                  isCardInFeed
                  isCommentReactionInFeed
                />
              );
            })}
            {canLoadMore && (
              <LoadMore
                loadMorePending={loadMorePending}
                loadMoreTextClassName={`${bc}__refresh-btn`}
                loadMoreWrapperClassName={`${bc}__load-more-group`}
                onLoadMoreClick={() => loadMoreActivitiesStories({ ...pagination, page: nextPage })}
              />
            )}
          </div>
        </>
      ) : (
        <div className={`${bc}__empty-block-wrapper`}>
          <Text size={500} weight="semibold" className={`${bc}__empty-block-title`}>
            Team's Story Feed
          </Text>
          <Text className={`${bc}__empty-block-sub-title`}>No one has shared a Story yet. Be the first one!</Text>
          <Button
            disabled={pending || loadMorePending}
            icon={<ArrowClockwise20Regular />}
            onClick={() => runFunctions()}
          >
            Refresh
          </Button>
        </div>
      )}
    </div>
  );
};

export default StoryFeed;
