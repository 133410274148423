export const canLoadMore = ({
  limit,
  page,
  count,
}: {
  limit: number;
  page: number;
  count: number;
}): {
  nextPage: number;
  canLoadMore: boolean;
} => {
  if (limit > count) {
    return { nextPage: 0, canLoadMore: false };
  }

  const maxAllowedPage = Math.ceil(count / limit);
  if (page < maxAllowedPage) {
    return { nextPage: page + 1, canLoadMore: true };
  }

  return { nextPage: maxAllowedPage, canLoadMore: false };
};
