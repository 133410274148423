import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ETheme, IUIState } from './interfaces';
import { setUITheme } from './actions';

const initialState: IUIState = {
  theme: ETheme.DEFAULT,
};

const handleUIState = (state: IUIState, action: PayloadAction<ETheme>) => {
  state.theme = action.payload;
  return state;
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {},
  extraReducers: {
    [setUITheme.toString()]: handleUIState,
  },
});

export const ui = uiSlice.reducer;
