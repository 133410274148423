import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { fromBase64, writeLog } from '@prohabits/metaverse-v2';
import { parseQuery } from '../../helpers/urlHelper';
import { ILaunchProps } from './interface';

const Launch = ({ navigate, fetchAccessToken, accessToken, accessTokenWasFetched }: ILaunchProps) => {
  console.log('__Launch__');
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hash = urlParams.get('hash');
    if (!hash) {
      navigate('/login');
      return;
    }
    const decoded = fromBase64(hash) as any;
    writeLog({ message: '__decoded__', data: decoded });
    if (!decoded) {
      navigate('/login');
      return;
    }
    const removeDoubleQuotes = decoded.slice(1, -1);
    const parsed = parseQuery(removeDoubleQuotes) as {
      email: string | null;
      aadObjectId: string | null;
      token: string;
    };
    fetchAccessToken(parsed);
  }, []);
  if (accessToken && accessTokenWasFetched) {
    return <Navigate to="/" />;
  }
  return <div>Loader</div>;
};

export default Launch;
