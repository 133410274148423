import React, { useEffect, useRef } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { uniqBy } from 'lodash';
import { Text, makeStyles, tokens } from '@fluentui/react-components';
import cn from 'classnames';
import { getUserFullName } from '@prohabits/metaverse-v2';

import Label from '../../components/Label';
import ErrorMessage from '../../components/errorMessage';
import defaultStyle from './defaultStyle';
import SuggestionsContainer from './SuggestionsContainer';
import './TextAreaWithMention.css';
import { uiTheme } from './index';
import { current } from '@reduxjs/toolkit';

const DEFAULT_MAX_CHARS_LENGTH = 256;

const useStyles = makeStyles({
  textColor: { color: tokens.colorNeutralForeground1 },
});

const bc = 'text-area-with-mention';

const TextAreaWithMention = ({
  input,
  meta,
  label,
  onChangeCb,
  maxLength = DEFAULT_MAX_CHARS_LENGTH,
  // must be rewrited as mentionsUsers and replace -> users, because mentions it's a separate entity
  onAddMention,
  pureMentionsInputValue,
  setPureMentionsInputValue,
  showBottomInfoLabelInAbsolutePosition = false,
  showExtraInfoMessage = true,
  fetchUsersForMentions,
  usersForMentions,
  ...inputProps
}: any) => {
  const { onChange } = input;
  const handleChange = (plainText: any) => {
    if (plainText.length > maxLength) {
      return;
    }
    onChange(plainText);
    if (onChangeCb) {
      onChangeCb(plainText);
    }
  };
  const classes = useStyles();
  const ref = useRef<any>(null);
  useEffect(() => {
    const inputElement = ref?.current;
    if (inputElement) {
      /*
        useStyles returns a string with classes splited by space character
        like: ___10sigve_0000000 f19n0e5
        so in order to add them we should spread them to classList.add method
       */
      inputElement.classList.add(...classes.textColor.split(' '));
    }
  }, [ref]);
  return (
    <div className={bc}>
      {label ? (
        <div>
          <Label>{label} </Label>
        </div>
      ) : null}
      {meta.error && meta.touched && <ErrorMessage message={meta.error} />}
      <MentionsInput
        {...inputProps}
        inputRef={ref}
        className={`${bc}__input`}
        style={defaultStyle(uiTheme)}
        value={pureMentionsInputValue}
        customSuggestionsContainer={SuggestionsContainer}
        // TODO: @Max add interfaces
        onChange={(e, newValue, newPlainTextValue, mentions: { id: number }[]) => {
          /*
            value with markup for mentions must be used as value for the mentions input,looks like this: '@[__display__](__id__)'
            and at the same time - plain text value should be setted into redux-form
          */
          setPureMentionsInputValue(newValue);
          handleChange(newPlainTextValue);
          onAddMention(uniqBy(mentions, 'id').map(({ id }) => id));
        }}
        onFocus={() => {
          if (!usersForMentions.length) {
            fetchUsersForMentions();
          }
        }}
      >
        <Mention
          displayTransform={(id: any, name: any) => {
            return `@${name}`;
          }}
          trigger="@"
          data={usersForMentions.map((mention: any) => {
            const { email, name, lastName, id } = mention;
            return {
              id,
              display: getUserFullName(name, lastName, email),
            };
          })}
          renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => {
            return (
              <div
                style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                className={`user ${focused ? 'focused' : ''}`}
              >
                <div> {highlightedDisplay} </div>
              </div>
            );
          }}
          appendSpaceOnAdd
        />
      </MentionsInput>
      {showExtraInfoMessage && (
        <div>
          <Text
            className={cn(`${bc}__bottom-info`, {
              [`${bc}__bottom-info-absolute`]: showBottomInfoLabelInAbsolutePosition,
            })}
          >
            You can tag someone in the story using “@“ before name
          </Text>
        </div>
      )}
    </div>
  );
};

export default TextAreaWithMention;
