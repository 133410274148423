import React from 'react';
import { Spinner, Text } from '@fluentui/react-components';
import { ArrowDownload24Regular } from '@fluentui/react-icons';
import { ILoadMoreProps } from './interface';

export default ({
  loadMorePending,
  loadMoreWrapperClassName,
  loadMoreTextClassName,
  onLoadMoreClick,
}: ILoadMoreProps) => {
  return (
    <div>
      {loadMorePending ? (
        <div className={loadMoreWrapperClassName}>
          <Spinner />
        </div>
      ) : (
        <div onClick={onLoadMoreClick} className={loadMoreWrapperClassName}>
          <ArrowDownload24Regular />
          <Text className={loadMoreTextClassName} size={400}>
            Load more
          </Text>
        </div>
      )}
    </div>
  );
};
