import React from 'react';
import { Button } from '@fluentui/react-components';
import LikeAndDislikeButton from '../LikeAndDislikeButton';
import { ISubmitAndLikeDislikeButtonsGroup } from './interface';

import './styles.scss';

const bc = 'submit-and-like-dislike-buttons-group';

export const SubmitAndLikeDislikeButtonsGroup = ({
  handleSubmit,
  setLikeOrDislike,
  isLiked,
  likesCount,
  buttonTitle = 'Submit',
  form = '',
  userActivityId,
  activityId,
}: ISubmitAndLikeDislikeButtonsGroup) => {
  return (
    <div className={bc}>
      <div className={`${bc}__actions`}>
        <Button form={form} type="submit" onClick={handleSubmit} className={`${bc}__commit-btn`} appearance="primary">
          {buttonTitle}
        </Button>
        <LikeAndDislikeButton
          userActivityId={userActivityId}
          setLikeOrDislike={setLikeOrDislike}
          isLiked={isLiked}
          likesCount={likesCount}
          activityId={activityId}
        />
      </div>
    </div>
  );
};
