import React from 'react';
import StoryFeedCard from 'app/containers/StoryFeedCard';
import { Modal } from 'app/components/Modal/Modal';
import { IActivityStoryData } from 'app/store/entities/activitiesStories/interfaces';
import { IActivityCompletedStory } from 'app/containers/StoryFeed/interface';

export const MentionModal = ({
  story,
  open,
  onClose,
}: {
  story: IActivityCompletedStory;
  open: boolean;
  onClose: (flag: boolean) => void;
}) => {
  if (!story.activityUserStory.id) {
    return null;
  }

  const { activityUserStory, reactions, user, timestamp, activitiesUsersStoriesComments } = story;

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <StoryFeedCard
        key={activityUserStory.id}
        story={activityUserStory}
        reactions={reactions}
        user={user}
        timestamp={timestamp}
        storyIndex={0}
        comments={activitiesUsersStoriesComments}
        isCommentReactionInMentionModal
        isMentionModalStoryReaction
      />
    </Modal>
  );
};
