import React from 'react';
import cn from 'classnames';
import { EMoodCheckEmoji } from '@prohabits/metaverse-v2';
import { Field } from 'react-final-form';

import { Angry } from 'app/icons/Angry';
import { Happy } from 'app/icons/Happy';
import { Sad } from 'app/icons/Sad';
import { Content } from 'app/icons/Content';
import { Tired } from 'app/icons/Tired';
import { IMoodCheckListProps } from './interfaces';

import './styles.scss';

const bc = 'mood-check-icons-list';

export default ({ fieldName = 'simple-card' }: IMoodCheckListProps) => {
  const handleActiveIndexUpdate = (iconName: EMoodCheckEmoji, onChange: (val: EMoodCheckEmoji) => void) => () => {
    onChange(iconName);
  };

  return (
    <div data-testid="simple-card-moodcheck" className={bc}>
      {[
        { Icon: Happy, iconName: EMoodCheckEmoji.happy },
        { Icon: Content, iconName: EMoodCheckEmoji.content },
        { Icon: Sad, iconName: EMoodCheckEmoji.sad },
        { Icon: Tired, iconName: EMoodCheckEmoji.tired },
        { Icon: Angry, iconName: EMoodCheckEmoji.angry },
      ].map(({ Icon, iconName }, idx) => {
        return (
          <Field name={fieldName}>
            {({ input }) => {
              return (
                <div onClick={handleActiveIndexUpdate(iconName, input.onChange)}>
                  <Icon
                    width={63}
                    height={63}
                    key={idx}
                    svgClassName={cn(`${bc}__icon`, { [`${bc}__icon-active`]: input.value === iconName })}
                  />
                </div>
              );
            }}
          </Field>
        );
      })}
    </div>
  );
};
