export const handleLikeOrDislike = async ({
  clickOnLike,
  isLiked,
  setLikeOrDislike,
  userActivityId,
}: {
  clickOnLike: boolean;
  isLiked: boolean | null;
  setLikeOrDislike: (args: {
    userActivityId: number;
    likeValue: boolean | null;
    previousValue: boolean | null;
  }) => void;
  userActivityId: number;
}) => {
  /* pressing like one more time  */
  if (isLiked && clickOnLike) {
    return setLikeOrDislike({ userActivityId, likeValue: null, previousValue: true });
    /* pressing dislike one more time  */
  } else if (isLiked === false && !clickOnLike) {
    return setLikeOrDislike({ userActivityId, likeValue: null, previousValue: false });
    /* pressing like if dislike already pressed */
  } else if (isLiked === false && clickOnLike) {
    return setLikeOrDislike({ userActivityId, likeValue: true, previousValue: false });
    /* pressing dislike if like already pressed */
  } else if (isLiked && !clickOnLike) {
    return setLikeOrDislike({ userActivityId, likeValue: false, previousValue: true });
  } else {
    return setLikeOrDislike({ userActivityId, likeValue: clickOnLike, previousValue: null });
  }
};
