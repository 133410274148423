import { createSlice } from '@reduxjs/toolkit';
import {
  fetchActivities,
  getLikesCountByActivityId,
  postActivity,
  postStory,
  setLikeOrDislike,
  setMarkAsViewed,
  setMountedActivity,
} from './actions';
import { ECardActionType, IActivityCard } from '@prohabits/metaverse-v2';
import { IActivityWithLikesCount } from 'app/containers/ActivityCardSimple/interface';

export interface IActivitiesState {
  list: IActivityWithLikesCount[];
  mountedActivityId: number | null;
}

const initialState: IActivitiesState = {
  // @ts-ignore
  list: [{ userActivityId: 0, structure: { type: '' }, likesCount: 0 }] as IActivityCard[],
  mountedActivityId: null,
};

const handlePostActivityFulfilled = (
  state: IActivitiesState,
  { payload }: { payload: { data: { userActivityId: number; actionType?: ECardActionType; answer?: object } } },
) => {
  const {
    data: { userActivityId, actionType, answer },
  } = payload;
  const updateActivities = state.list.map((activity) => {
    if (activity.userActivityId === userActivityId) {
      activity.actionType = actionType || null;
      // @ts-ignore
      activity.answer = answer || null;
    }
    return activity;
  });
  state.list = updateActivities;
  return state;
};

const handleFetchActivities = (
  state: IActivitiesState,
  { payload }: { payload: { data: IActivityWithLikesCount[] } },
) => {
  state.list = payload.data;
};

const handlePostStory = (
  state: IActivitiesState,
  { payload }: { payload: { data: { userActivityId: number; id: number; story: string } } },
) => {
  const { data } = payload;
  const { userActivityId, id, story } = data;
  const updatedList = state.list.map((activity) => {
    if (activity.userActivityId === userActivityId) {
      activity.storyId = id;
      activity.story = story;
    }
    return activity;
  });
  state.list = updatedList;
  return state;
};

const handleSetMarkAsViewed = (
  state: IActivitiesState,
  { payload }: { payload: { data: { id: number; openedAt: Date } } },
) => {
  const { data } = payload;
  state.list = state.list.map((activity) => {
    if (activity.userActivityId === data.id) {
      activity.openedAt = data.openedAt;
    }
    return activity;
  });
  return state;
};

const handleLikeOrDislike = (
  state: IActivitiesState,
  {
    payload,
    meta,
  }: {
    payload: { data: { userActivityId: number; likeDislike: boolean | null } };
    meta: { arg: { likeValue: boolean; previousValue: boolean } };
  },
) => {
  const {
    data: { likeDislike, userActivityId },
  } = payload;
  const {
    arg: { likeValue, previousValue },
  } = meta;
  const activityToUpdIdx = state.list.findIndex((activity) => activity.userActivityId === userActivityId);
  state.list[activityToUpdIdx].likeDislike = likeDislike;
  if (!previousValue && likeValue) {
    state.list[activityToUpdIdx].likesCount += 1;
  } else if (previousValue && !likeValue) {
    state.list[activityToUpdIdx].likesCount -= 1;
  }
  return state;
};

const handleGetLikesCount = (
  state: IActivitiesState,
  { payload }: { payload: { data: { likesCount: number; activityId: number } } },
) => {
  const {
    data: { likesCount, activityId },
  } = payload;

  const activityToUpdIdx = state.list.findIndex((activity) => activity.activityId === activityId);
  state.list[activityToUpdIdx].likesCount = likesCount;
};

const handleSetMountedActivity = (
  state: IActivitiesState,
  { payload }: { payload: { data: { activityId: number } } },
) => {
  const {
    data: { activityId },
  } = payload;
  state.mountedActivityId = activityId;
  return state;
};

export const activities = createSlice({
  name: 'activities',
  initialState,
  reducers: {},
  extraReducers: {
    [postActivity.fulfilled.toString()]: handlePostActivityFulfilled,
    [fetchActivities.fulfilled.toString()]: handleFetchActivities,
    [postStory.fulfilled.toString()]: handlePostStory,
    [setMarkAsViewed.fulfilled.toString()]: handleSetMarkAsViewed,
    [setLikeOrDislike.fulfilled.toString()]: handleLikeOrDislike,
    [getLikesCountByActivityId.fulfilled.toString()]: handleGetLikesCount,
    [setMountedActivity.toString()]: handleSetMountedActivity,
  },
});

export const activitiesSlice = activities.reducer;
