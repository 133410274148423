import { EMOJI_TADA, EMOJI_HEART, EMOJI_FIRE, EMOJI_BLUSH, EMOJI_RAISED_HANDS } from '@prohabits/metaverse-v2';

import {
  ThumbLike20Regular,
  Heart20Regular,
  Balloon20Regular,
  Rocket20Regular,
  StarEmphasis20Regular,
} from '@fluentui/react-icons';
export const EMOJIS = [
  {
    name: EMOJI_TADA,
    icon: ThumbLike20Regular,
  },
  { name: EMOJI_HEART, icon: Heart20Regular },
  { name: EMOJI_BLUSH, icon: Balloon20Regular },
  { name: EMOJI_FIRE, icon: Rocket20Regular },
  { name: EMOJI_RAISED_HANDS, icon: StarEmphasis20Regular },
];

export const EMOJIS_BY_ICONS_NAMES = EMOJIS.reduce((acum, cur) => {
  // @ts-ignore
  acum[cur.name] = cur.icon;
  return acum;
}, {});
