import { connect } from 'react-redux';
import { MoodCheck } from './MoodCheck';
import { getMoodCheckStatuses } from '../../store/entities/moodCheck/selectors';
import { setMoodCheck } from '../../store/entities/moodCheck';
import { EMoodCheckEmoji } from '@prohabits/metaverse-v2';
import { RootState } from '../../store';

const mapStateToProps = (state: RootState) => ({
  moodCheckStatuses: getMoodCheckStatuses(state),
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateMoodCheck: (emoji: EMoodCheckEmoji) => dispatch(setMoodCheck({ emoji })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MoodCheck);
