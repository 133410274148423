import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import {
  fetchIdentity,
  getIdentityTenantId,
  getIdentityAadObjectId,
  putProfileSettings,
} from '../../store/entities/identity';
import { RootState } from 'app/store';
import {
  fetchActivities,
  getFilteredActivitiesList,
  getLikesCountByActivityId,
  getMountedActivityId,
  getMountedActivityLikesCount,
} from 'app/store/entities/activities';
import { getIsMicrosoftTeams } from 'app/store/entities/techInfo';
import { fetchStoryForMention } from 'app/store/entities/activitiesStories/actions';
import { getStoryForMentionModal } from 'app/store/entities/activitiesStories/selectors';

const mapStateToProps = (state: RootState) => {
  return {
    activities: getFilteredActivitiesList(state),
    isMicrosoftTeams: getIsMicrosoftTeams(state),
    tenantId: getIdentityTenantId(state),
    aadObjectId: getIdentityAadObjectId(state),
    storyForMentionModal: getStoryForMentionModal(state),
    mountedActivityId: getMountedActivityId(state),
    mountedActivityLikesCount: getMountedActivityLikesCount(state),
  };
};
const mapDispatchToProps = {
  fetchIdentity,
  fetchActivities,
  putProfileSettings,
  fetchStoryForMention,
  getLikesCountByActivityId,
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard as any);
