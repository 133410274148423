import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IWithNavigate } from './interface';

function WithNavigate<T extends IWithNavigate>(WrappedComponent: React.ComponentType<T>) {
  // The returned component class
  return function (props: Omit<T, keyof IWithNavigate>) {
    const navigate = useNavigate();
    return <WrappedComponent {...(props as T)} navigate={navigate} />;
  };
}

export default WithNavigate;
