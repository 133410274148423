import * as React from 'react';
import { Field, Radio, RadioGroup } from '@fluentui/react-components';
import { Field as FormField } from 'react-final-form';

const bc = 'radio-button';

const RadioButton = ({ options, fieldName }: { options: any[]; fieldName: string }) => {
  return (
    <div data-testid="simple-card-radio" className={bc}>
      <FormField name={fieldName}>
        {({ input }) => {
          return (
            <Field>
              <RadioGroup name={fieldName} defaultValue={input.value}>
                {options.map((option) => {
                  return (
                    <Radio
                      onChange={() => {
                        input.onChange(option.value);
                      }}
                      key={option.value}
                      label={option.label}
                      value={option.value}
                    />
                  );
                })}
              </RadioGroup>
            </Field>
          );
        }}
      </FormField>
    </div>
  );
};

export default RadioButton;
