import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../api';
import { IActivityStoryData, IFetchOrLoadMoreActivitiesStories } from './interfaces';
import {
  ISetStoryCommentReaction,
  ISetStoryReaction,
  IUpdateStoryCommentReaction,
  IUpdateStoryReaction,
} from '../../../containers/Reactions/interface';
import { IAddComment } from '../../../containers/StoryFeedCard/AddComment/interface';

const request = async (loadInfo?: IFetchOrLoadMoreActivitiesStories): Promise<IActivityStoryData> => {
  let { page = 1, limit = 50 } = loadInfo || {};
  let url = `/stories?page=${page}&limit=${limit}`;
  const { data } = await axios.get(url, {});
  return data;
};

// this one is used for initial state loading and refresh button
export const fetchActivitiesStories = createAsyncThunk('fetchActivitiesStories/request', request);
// this one is reset stories and get rid of duplicate stories
export const resetStories = createAction('resetStories');
// this one is used for the load more
export const loadMoreActivitiesStories = createAsyncThunk('loadMoreActivitiesStories/request', request);

export const updateCommentReaction = createAsyncThunk(
  'updateCommentReaction/request',
  async (reaction: IUpdateStoryCommentReaction) => {
    const { data } = await axios.post('/reactions', reaction);
    return data;
  },
);

export const updateStoryReaction = createAsyncThunk(
  'updateStoryReaction/request',
  async (reaction: IUpdateStoryReaction) => {
    const { data } = await axios.post('/reactions', reaction);
    return data;
  },
);

export const setCommentReaction = createAsyncThunk(
  'setCommentReaction/request',
  async (reaction: ISetStoryCommentReaction) => {
    const { data } = await axios.put('/reactions', reaction);
    return data;
  },
);

export const setStoryReaction = createAsyncThunk('setStoryReaction/request', async (reaction: ISetStoryReaction) => {
  const { data } = await axios.put('/reactions', reaction);
  return data;
});

export const addComment = createAsyncThunk(
  'addComment/request',
  async ({ storyId, comment, mentions }: IAddComment) => {
    const { data } = await axios.put('/comment', { storyId, comment, mentions: mentions.map((id) => parseInt(id)) });
    return data;
  },
);

export const fetchUsersForMentions = createAsyncThunk('fetchMentionsUsers/request', async () => {
  const { data } = await axios.get('users/mentions-users/list');
  return data;
});

export const fetchStoryForMention = createAsyncThunk(
  'fetchStoryForMentionModal/request',
  async ({ storyId, commentId }: { storyId?: number; commentId?: number }) => {
    let queryString = '';
    if (storyId) {
      queryString = `storyId=${storyId}`;
    }
    if (commentId) {
      queryString = `commentId=${commentId}`;
    }
    const { data } = await axios.get(`stories/user-mention?${queryString}`);
    return data;
  },
);
