import React from 'react';
import { Text, Button } from '@fluentui/react-components';
import { Field } from 'react-final-form';

import Select from '../../components/Select';
import Input from '../../components/Input';
import { GlobalToaster } from '../../components/GlobalToaster/GlobalToaster';
import './styles.css';
import { ISettingsPageFormProps } from './interface';
import UserNotificationTypes from '../../components/UserNotificationTypes/UserNotificationTypes';
import { uiTheme } from './index';
import Header from 'app/components/Header';

const bc = 'settings-page-form';

const SettingsPageForm: React.FC<ISettingsPageFormProps> = ({
  timezones,
  reset,
  handleSubmit,
  // uiTheme,
  values,
  phone,
}) => {
  return (
    <>
      <GlobalToaster title={'Success!'} body={'Your settings were updated.'} />
      <Header showDashboardLink />
      <form className={bc}>
        <div className={`${bc}__content-wrapper`}>
          <>
            <Text
              className="settings-page-form__content-header settings-page-form__text-offset"
              weight="bold"
              size={400}
            >
              Profile
            </Text>
            <Field component={Select} name="timezone" label="Timezone" options={timezones} isControlled />
            <div style={{ paddingTop: '20px' }}>
              <Text
                className="settings-page-form__content-header settings-page-form__text-offset"
                weight="bold"
                size={400}
              >
                Send Notifications Via:
              </Text>
            </div>
            <Field name="notificationType" component={UserNotificationTypes} phone={phone} />
            {/* checking values because tests are failed here. Must investigate how use react-final-form with tests */}
            {values && values.notificationType.sms && (
              <>
                <Field
                  name="phone"
                  component={Input}
                  label="Your USA Number"
                  value={values.phone}
                  fluid
                  placeholder="+1xxxxxxxxxx"
                />
              </>
            )}
          </>
          <div className={`settings-page-form__buttons-wrapper settings-page-form__buttons-wrapper__${uiTheme}`}>
            <div className={`${bc}__submit-buttons`}>
              <Button className={`${bc}__discard`} onClick={reset} type="button">
                Discard
              </Button>
              <Button className={`${bc}__button`} onClick={handleSubmit} type="submit">
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default SettingsPageForm;
