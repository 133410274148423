import React, { useEffect } from 'react';
import { Image, Spinner, Text } from '@fluentui/react-components';
import { app } from '@microsoft/teams-js';
import { Navigate } from 'react-router-dom';

import './styles.scss';
import { fromBase64 } from '@prohabits/metaverse-v2';
import { parseQuery } from 'app/helpers/urlHelper';
import { IMAGE_LOGO_PROHABITS } from '../../constants/image';

const bc = 'teams-login';
export default ({
  accessToken,
  mstTokenBase64,
  isMstTokenWasFetched,
  fetchMstTokenBase64,
  setIsMicrosoftTeams,
  fetchAccessToken,
  logoutSuccess,
}: {
  fetchMstTokenBase64: ({ uid }: { uid: string }) => any;
  accessToken: string | null;
  mstTokenBase64: string | null;
  isMstTokenWasFetched: boolean;
  setIsMicrosoftTeams: () => void;
  fetchAccessToken: (payload: { email: string | null; aadObjectId: string | null; token: string }) => void;
  logoutSuccess: () => void;
}) => {
  useEffect(() => {
    setIsMicrosoftTeams();
    /* 
      perform a clean-up for accessToken, because if the user has logged in from another MS account
      accessToken will be cached as we using redux-persist 
     */
    logoutSuccess();
    app.getContext().then((context) => {
      fetchMstTokenBase64({ uid: context.user?.userPrincipalName as string });
    });
  }, []);

  useEffect(() => {
    if (isMstTokenWasFetched) {
      const decoded = fromBase64(mstTokenBase64 as string) as string;
      const removeDoubleQuotes = decoded.slice(1, -1);
      const parsed = parseQuery(removeDoubleQuotes) as {
        email: string | null;
        aadObjectId: string | null;
        token: string;
      };
      fetchAccessToken(parsed);
    }
  }, [isMstTokenWasFetched]);

  if (!isMstTokenWasFetched) {
    return (
      <div className={bc}>
        <Spinner />
      </div>
    );
  }

  if (accessToken) {
    return <Navigate to={`/`} />;
  }

  return (
    <div className={bc}>
      {!mstTokenBase64 && (
        <div className={`${bc}__global-error`}>
          <Image className={`${bc}__img`} src={IMAGE_LOGO_PROHABITS} alt="ProHabits Logo" />
          <div className={`${bc}__text-wrapper`}>
            <Text className={`${bc}__title`} size={500} weight="semibold">
              Your ProHabits journey will begin soon.
            </Text>
            <Text size={300} weight="regular" align="center">
              To gain access, please send an email to the address happy@prohabits.com,
              <br />
              in addition to keeping an eye out for notifications from this app in the near future!
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};
