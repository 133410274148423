import React, { useEffect } from 'react';
import {
  ThumbLike16Regular,
  ThumbLike16Filled,
  ThumbDislike16Regular,
  ThumbDislike16Filled,
} from '@fluentui/react-icons';
import { Button } from '@fluentui/react-components';
import { Field, Form } from 'react-final-form';

import { ILikeAndDislikeButtonProps } from './interface';
import { handleLikeOrDislike } from './helpers';

import './LikeAndDislikeButton.css';

const bc = 'like-and-dislike-button';

export default ({
  setLikeOrDislike,
  isLiked,
  userActivityId,
  activityId,
  likesCount,
  setMountedActivity,
}: ILikeAndDislikeButtonProps) => {
  useEffect(() => {
    setMountedActivity(activityId);
  }, [userActivityId]);
  return (
    <Form
      onSubmit={(values) => {
        return handleLikeOrDislike({ clickOnLike: values.like, isLiked, setLikeOrDislike, userActivityId });
      }}
      /* 
       Important note: value of submitting prop will change after form submission 
       only in case when a handler provided as a onSubmit prop will return a promise
      */
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit} className={bc}>
            <Field
              name="like"
              render={({ input }) => {
                return (
                  <Button
                    type="submit"
                    disabled={submitting}
                    onClick={() => {
                      input.onChange(true);
                    }}
                    shape="square"
                    className={`${bc}__like`}
                    size="small"
                    icon={isLiked ? <ThumbLike16Filled /> : <ThumbLike16Regular />}
                  >
                    {likesCount}
                  </Button>
                );
              }}
            />
            <Field
              name="like"
              render={({ input }) => {
                return (
                  <Button
                    disabled={submitting}
                    type="submit"
                    shape="square"
                    className={`${bc}__dislike`}
                    size="small"
                    icon={isLiked === false ? <ThumbDislike16Filled /> : <ThumbDislike16Regular />}
                    onClick={() => {
                      input.onChange(false);
                    }}
                  />
                );
              }}
            />
          </form>
        );
      }}
    ></Form>
  );
};
