import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { identitySlice } from './entities/identity';
import { moodCheckStatusesSlice } from './entities/moodCheck';
import queriesStatusesSlice from './entities/queriesStatuses/slices';
import activitiesStories from './entities/activitiesStories/slices';
import { activitiesSlice } from './entities/activities';
import { techInfoSlice } from './entities/techInfo/slices';
import { ui } from './entities/ui/slices';

const persistConfig = {
  key: 'prohabits',
  storage,
  whitelist: ['identity'],
};

const rootReducer = combineReducers({
  identity: identitySlice,
  moodCheckStatuses: moodCheckStatusesSlice,
  queriesStatuses: queriesStatusesSlice,
  activitiesStories: activitiesStories,
  ui: ui,
  activities: activitiesSlice,
  techInfo: techInfoSlice,
});
const reducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer,
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
