import React from 'react';
import { tokens } from '@fluentui/react-theme';
import cn from 'classnames';

import { Text, Image, makeStyles } from '@fluentui/react-components';
import { IActivityCardTemplateProps } from './interface';

import './styles.scss';

const bc = 'activity-card-template';

const useStyles = makeStyles({
  background: { backgroundColor: tokens.colorNeutralForegroundInverted },
});

const ActivityCardTemplate = (props: IActivityCardTemplateProps) => {
  const { header, title, description, image, children } = props;
  const classes = useStyles();

  return (
    <div className={cn(bc, classes.background)}>
      <Text className={`${bc}__title`} weight="medium">
        {header}
      </Text>
      <div className={cn(`${bc}__body ${classes.background}`)}>
        {Boolean(image) && <Image src={image} block />}
        <Text className={`${bc}__body-title`}>{title}</Text>
        <Text className={`${bc}__body-description`} size={300}>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Text>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default ActivityCardTemplate;
