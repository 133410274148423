import { connect } from 'react-redux';
import {
  fetchActivitiesStories,
  loadMoreActivitiesStories,
  resetStories,
} from '../../store/entities/activitiesStories/actions';
import StoryFeed from './StoryFeed';
import {
  getActivitiesStoriesLoadMorePending,
  getActivitiesStoriesPagination,
  getActivitiesStoriesPaginationDetails,
  getActivitiesStoriesPending,
  getStories,
} from '../../store/entities/activitiesStories/selectors';
import { getIdentityActivityId } from '../../store/entities/identity';
import { RootState } from '../../store';

const mapStateToProps = (state: RootState) => ({
  stories: getStories(state),
  activityId: getIdentityActivityId(state),
  pagination: getActivitiesStoriesPagination(state),
  paginationDetails: getActivitiesStoriesPaginationDetails(state),
  pending: getActivitiesStoriesPending(state),
  loadMorePending: getActivitiesStoriesLoadMorePending(state),
  // uiTheme: getUITheme(state),
});

const mapDispatchToProps = {
  fetchActivitiesStories,
  loadMoreActivitiesStories,
  resetStories,
};

const mergeProps = (
  { activityId, pagination, ...stateProps }: any,
  { fetchActivitiesStories, loadMoreActivitiesStories, resetStories, ...dispatchProps }: any,
  ownProps: any,
) => {
  const runFunctions = async (): Promise<void> => {
    const page = 1;
    const limit = 50;
    try {
      resetStories();
      await fetchActivitiesStories({ page, limit });
    } catch (error) {
      console.error(error);
    }
  };
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    pagination,
    loadMoreActivitiesStories,
    activityId,
    runFunctions,
  };
};
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(StoryFeed as any) as any;
