import React, { useState } from 'react';
import { Text } from '@fluentui/react-components';
import Modal from '../../Modal';
import './styles.scss';

const bc = 'login-modal';

export const LoginModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  return (
    <div className={bc}>
      <Modal open={open} onClose={onClose}>
        <div className={`${bc}__content`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="136" height="136" viewBox="0 0 136 136">
            <defs>
              <filter
                id="aCheckEmailSpamIcon"
                width="141.7%"
                height="133.3%"
                x="-20.8%"
                y="-16.7%"
                filterUnits="objectBoundingBox"
              >
                <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4" />
                <feColorMatrix
                  in="shadowBlurOuter1"
                  result="shadowMatrixOuter1"
                  values="0 0 0 0 0.851482781 0 0 0 0 0.851482781 0 0 0 0 0.851482781 0 0 0 0.5 0"
                />
                <feMerge>
                  <feMergeNode in="shadowMatrixOuter1" />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
              <path
                id="c1"
                d="M2.632.188h92.315a2 2 0 0 1 2 2v63.894a2 2 0 0 1-2 2H2.632a2 2 0 0 1-2-2V2.188a2 2 0 0 1 2-2z"
              />
              <filter
                id="bCheckEmailSpamIcon"
                width="149.8%"
                height="170.7%"
                x="-24.9%"
                y="-35.3%"
                filterUnits="objectBoundingBox"
              >
                <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8" />
                <feColorMatrix
                  in="shadowBlurOuter1"
                  values="0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 0.2 0"
                />
              </filter>
            </defs>
            <g fill="none" fill-rule="evenodd" filter="url(#aCheckEmailSpamIcon)" transform="translate(8 6)">
              <path fill="#2AB8FF" fill-rule="nonzero" d="M0 39.934h120v79.868H0z" />
              <path fill="#087acb" fill-rule="nonzero" d="M0 39.934L60 0l60 39.934-60 39.934L0 39.934z" />
              <g transform="translate(11.21 17.97)">
                <use fill="#000" filter="url(#bCheckEmailSpamIcon)" xlinkHref="#cCheckEmailSpamIcon" />
                <use fill="#FFF" xlinkHref="#cCheckEmailSpamIcon" />
                <rect
                  width="37.895"
                  height="37.895"
                  x="13.263"
                  y="12.819"
                  fill="#E9E9E9"
                  fill-rule="nonzero"
                  rx="3.158"
                />
                <rect
                  width="26.842"
                  height="6.316"
                  x="58.263"
                  y="22.293"
                  fill="#E9E9E9"
                  fill-rule="nonzero"
                  rx="3.158"
                />
                <rect
                  width="26.842"
                  height="6.316"
                  x="58.263"
                  y="34.924"
                  fill="#E9E9E9"
                  fill-rule="nonzero"
                  rx="3.158"
                />
              </g>
              <path fill="#087acb" fill-rule="nonzero" d="M60 79.868l60 39.934H0z" />
              <path fill="#319dea" fill-rule="nonzero" d="M60 79.868L0 119.802V39.934zM60 80.038l59.5-39.934v79.868z" />
              <g transform="translate(88 2)">
                <circle cx="16" cy="16" r="16" fill="#FF8484" />
                <text fill="#FFF" font-family="Roboto-Bold, Roboto" font-size="16" font-weight="bold">
                  <tspan x="11.41" y="20">
                    1
                  </tspan>
                </text>
              </g>
            </g>
          </svg>
        </div>
        <div className={`${bc}__text-wrapper`}>
          <Text align="center">
            If you're enrolled in ProHabits, you will be sent an email containing a sign in link.
          </Text>
        </div>
      </Modal>
    </div>
  );
};
