import { createSlice } from '@reduxjs/toolkit';
import { fetchAccessToken, fetchIdentity, fetchMstTokenBase64, putProfileSettings } from '../identity';
import { resetToast } from './actions';

export interface IQueriesStatusesState {
  putProfileSettings: {
    success: boolean;
  };
  getIdentity: {
    success: boolean;
  };
  showToast: boolean;
  isMstTokenWasFetched: boolean;
  accessTokenWasFetched: boolean;
}

const initialState = {
  putProfileSettings: {
    success: false,
  },
  getIdentity: {
    success: false,
  },
  showToast: false,
  isMstTokenWasFetched: false,
  accessTokenWasFetched: false,
};
const handlePutProfileSettingsFulfilled = (state: IQueriesStatusesState) => {
  state.putProfileSettings.success = true;
  state.showToast = true;
  return state;
};

const handleGetIdentityFulfilled = (state: IQueriesStatusesState) => {
  state.getIdentity.success = true;
  return state;
};

const handleResetToast = (state: IQueriesStatusesState) => {
  state.showToast = false;
  return state;
};

const handleMstTokenFetch = (state: IQueriesStatusesState) => {
  state.isMstTokenWasFetched = true;
  return state;
};

const handleAccessTokenWasFetch = (state: IQueriesStatusesState) => {
  state.accessTokenWasFetched = true;
  return state;
};

const queriesStatusesSlice = createSlice({
  name: 'queriesStatuses',
  initialState,
  reducers: {},
  extraReducers: {
    [putProfileSettings.fulfilled.toString()]: handlePutProfileSettingsFulfilled,
    [fetchIdentity.fulfilled.toString()]: handleGetIdentityFulfilled,
    [resetToast.toString()]: handleResetToast,
    [fetchMstTokenBase64.fulfilled.toString()]: handleMstTokenFetch,
    [fetchAccessToken.fulfilled.toString()]: handleAccessTokenWasFetch,
  },
});

export default queriesStatusesSlice.reducer;
